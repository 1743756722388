const authApi = (rootUrl) => {

    return {
        
        login: (data) => {

            let json = {
                "username": data.username,
                "password": data.password        
            }

            return fetch(`${rootUrl}/users/authenticate`, 
                {
                    method: 'POST',
                    body: JSON.stringify(json),
                    headers:{
                        'Content-Type': 'application/json',
                        'accessToken': "",
                    }
                }
            ).then(res => {
                return res.json().then(response => {

                    if(response.error){
                        if(response.error === "User does not exist."){
                            response.error = "El usuario no existe.";
                        }
                        if(response.error === "Incorrect username or password."){
                            response.error = "Nombre de usuario o clave de acceso incorrectos.";
                        }
                        return Promise.reject(response);
                    }
    
                    return Promise.resolve(response);
                })
            })
        },
        signUp: (data) => {

            return fetch(`${rootUrl}/users/singUp`, 
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers:{
                        'Content-Type': 'application/json',
                        'accessToken': "",
                    }
                }
            ).then(res => {
                return res.json().then(response => {

                    if(response.error){
                        return Promise.reject(response);
                    }

                    return Promise.resolve(response);
                })
            })
        },
        confirmCode: ({username, code}) => {

            let data = {username, code};

            return fetch(`${rootUrl}/users/confirmSignup`, 
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers:{
                        'Content-Type': 'application/json',
                        'accessToken': "",
                    }
                }
            ).then(res => {
                return res.json().then(response => {

                    if(response.error){
                        if(response.error === "Invalid verification code provided, please try again."){
                            response.error = "Código de verificación inválido, por favor intente de nuevo.";
                        }
                        return Promise.reject(response);
                    }

                    return Promise.resolve(response);
                })
            })
        },
        confirmCode: ({username, code}) => {

            let data = {username, code};

            return fetch(`${rootUrl}/users/confirmSignup`, 
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers:{
                        'Content-Type': 'application/json',
                        'accessToken': "",
                    }
                }
            ).then(res => {
                return res.json().then(response => {

                    if(response.error){
                        if(response.error === "Invalid verification code provided, please try again."){
                            response.error = "Código de verificación inválido, por favor intente de nuevo.";
                        }
                        return Promise.reject(response);
                    }

                    return Promise.resolve(response);
                })
            })
        },
        confirmForgotPassword: ({username, password, code}) => {

            let data = {username, password, code};

            return fetch(`${rootUrl}/users/confirmForgotPassword`, 
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers:{
                        'Content-Type': 'application/json',
                        'accessToken': "",
                    }
                }
            ).then(res => {
                return res.json().then(response => {

                    if(response.error){
                        return Promise.reject(response);
                    }

                    return Promise.resolve(response);
                })
            })
        },
        forgotPassword: ({username}) => {

            let data = {username};

            return fetch(`${rootUrl}/users/forgotPassword`, 
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers:{
                        'Content-Type': 'application/json',
                        'accessToken': "",
                    }
                }
            ).then(res => {
                return res.json().then(response => {

                    if(response.error){
                        if(response.error === "Username/client id combination not found."){
                            response.error = "Email/nombre de usuario no encontrado.";
                        }
                        return Promise.reject(response);
                    }

                    return Promise.resolve(response);
                })
            })
        },
        isUsed: (field, data) => {

            return fetch(`${rootUrl}/users/isUsed?${field}=${data}`)
            .then(res => {
                return res.json().then(response => {

                    if(response.error){
                        return Promise.reject(response);
                    }

                    return Promise.resolve(!response.isUsed);
                })
            })
        },
        existEmailOrUser: (data) => {
            return fetch(`${rootUrl}/users/isUsedNameOrMail?username=${data}`)
            .then(res => {
                return res.json().then(response => {

                    if(response.error){
                        return Promise.reject(response);
                    }

                    return Promise.resolve(response.isUsed);
                })
            })
        }
    };
}

export default authApi;