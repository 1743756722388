import React from 'react';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';


import "./footer.css";

const Footer = () => {
    
    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -160; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }
    
    return (
    <div className="footer">
        <div className="container">
            <div className="row margin-bottom-3 padding-top-15">
                <div className="col-md-2 col-sm-12 cont-img-menu row-flex">
                        <NavHashLink smooth to="/#home" scroll={el => scrollWithOffset(el)}>
                            <img className="logo-menu" src={process.env.PUBLIC_URL + '/images/logo-white.svg'}  alt="logoChappsy" />
                        </NavHashLink>
                </div>
                <div className="col-md-6 col-sm-12 cont-img-menu">
                    <p className="texto-footer font-weight-600">¡Contáctanos!</p>
                    <div className="columna-flex">
                        <span className="texto-footer">10 calle 6-48 zona 9, Centro de Negocios Gibraltrar</span>
                        <span className="texto-footer">PBX: 2235-2960</span>
                        <span className="texto-footer">info@chappsy.com</span>
                        <Link className="texto-footer" to="/terms">Términos y Condiciones</Link>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12 text-center">
                    <p className="texto-footer font-weight-600">¡Síguenos en nuestras redes!</p>
                    <a href="https://www.facebook.com/chappsy/" target="_blank">
                        <img className="logo-redes" src={process.env.PUBLIC_URL + '/images/facebook.svg'} alt="redes"/> 
                    </a>
                    <a href="https://www.instagram.com/chappsygt/?hl=es-la" target="_blank">
                        <img className="logo-redes" src={process.env.PUBLIC_URL + '/images/instagram.svg'} alt="redes" />
                    </a>
                </div>
            </div>
            <div className="row justify-content-center">
                <p className="texto-footer text-center">© Chappsy Todos los derechos reservados 2020</p>
            </div>
        </div>
    </div>
)}

export default Footer;