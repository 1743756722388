import React, {useState, useEffect, useRef} from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import OnSubmitValidationError from "../../hooks/formikOnSubmitValidationError";
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import AuthApi from "../../api/authApi";
import { useGlobalState } from "../../hooks/useGlobalState";
import { makeStyles } from '@material-ui/core/styles';
import PhoneField from "../../components/phoneInput/phoneInput";
import jwt from 'jwt-decode'

const useStyles = makeStyles(theme => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AuthOrRegister = () => {
    
    const classes = useStyles();
    let history = useHistory();
    let { id } = useParams();
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [input, setInput] = useState({username: '', password: ''});
    const [passwordFocus, setFocus] = useState(false);
    const authService = AuthApi(window.runConfig.apiUrl);
    const formref = useRef(null);   
    const globalState = useGlobalState();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleForgotPassword = () => {
        
        history.push(`/subscription/${id}/forgotPassword`);
    }

    const handleLogin = (values) => {
        
        setOpen(true);
            
        authService.login(values).then(
            (result) => {
                setOpen(false);
                const user = jwt(result.IdToken);
                globalState.setUser({data: user, tokens: result});
                localStorage.setItem('accessToken', result.AccessToken);
                history.push(`/subscription/${id}/step1`);
            },

            (error) => {

                if(error.error === 'User is not confirmed.'){

                    let tempAuthData = {
                        username: values.username,
                        password: values.password
                    };
    
                    localStorage.setItem('tempAuthData', JSON.stringify(tempAuthData));
                    history.push(`/subscription/${id}/confirmCode`);
                }

                setOpen(false);
                setErrorMessage(error.error);
                setOpenAlert(true);
            }
        );
    }

    const handleRegister = ({name, username, email, password, phone}) => {

        let phoneClear = phone.replace('-', '');
        let data = { name, username, email, password, phone: `+502${phoneClear}`};
        
        setOpen(true);
            
        authService.signUp(data).then(
            (result) => {

                localStorage.setItem('signUpMail', JSON.stringify(result.codeDeliveryDetails.Destination));
                setOpen(false);

                let tempAuthData = {
                    username,
                    password
                };

                localStorage.setItem('tempAuthData', JSON.stringify(tempAuthData));
                history.push(`/subscription/${id}/confirmCode`);
            },

            (error) => {
                setOpen(false);
                setErrorMessage(error.error);
                setOpenAlert(true);
            }
        );
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlert(false);
    };

    const handleSubmitError = ({errors}) => {
        setErrorMessage("Hay algunos errores en el formulario");
        setOpenAlert(true);
    }

    const handleBlur = () => {
        setFocus(false);
    }

    const handleFocus = () => {
        setFocus(true);
    }

    const keyPressed = (event) => {
        if (event.key === "Enter" && passwordFocus == true) {
            if (formref.current) {
                formref.current.handleSubmit()
            }
        }
    }

    const inicialValueLogin = {
        'username': '',
        'password': ''
    }

    const loginValidationSchema = Yup.object(
        {
            username: Yup.string()
                .required('Nombre de usuario es requerido'),
            password: Yup.string()
                .required('Clave de acceso requerida'),
        });

    const inicialValueRegister = {
        'name': '',
        'email': '',
        'phone': '',
        'confirm_email': '',
        'username': '',
        'password': '',
        'confirm_password': ''
    }

    const registerValidationSchema = Yup.object(
        {
            name: Yup.string()
                .required('Campo requerido'),
            username: Yup.string()  
                .required('Campo requerido')
                .min(8, "Mínimo 8 caracteres")
                .test('notDots', 'Caracteres especiales no permitidos', function (value) {
                    return !(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g.test(value));
                })
                .test('checkDuplUsername', 'Nombre de usuario en uso', function (value) {
                    return new Promise((resolve, reject) => {
                        authService.isUsed('username', value)
                            .then((result) => resolve(result))
                    })
                }),
            phone: Yup.string()
                        .min(9, "Debe ingresar un teléfono válido")
                        .required('Campo requerido'),
            email: Yup.string()
                .test('checkDuplEmail', 'Email en uso', function (value) {
                    return new Promise((resolve, reject) => {
                        authService.isUsed('email', value)
                            .then((result) => resolve(result))
                    })
                })
                .required('Campo requerido'),
            confirm_email: Yup.string()
                .oneOf([Yup.ref('email'), null], 'Los correos deben coincidir'),
            password: Yup.string()
                .min(6, 'La clave debe ser minimo de 6 caracteres')
                .required('Campo requerido'),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Las claves deben coincidir'),
        });


    return (
        <div className="row padding-top-18">
            <div className="col-md-6 padding-50">
                <div className="text-center col-md-12 margin-bottom-3">
                    <span className="color-blue titulo1">Iniciar </span>
                    <span className="color-green titulo1">sesión</span>   
                </div>
                <Formik innerRef={formref} initialValues= {inicialValueLogin} validationSchema={loginValidationSchema} onSubmit={handleLogin} validateOnBlur={false}>   
                    {({ values,  errors, touched, handleSubmit, formik }) => 
                    ( 
                        <Form onSubmit={handleSubmit}>

                            <OnSubmitValidationError callback={handleSubmitError} />
                            <span className="texto-subs">Nombre de usuario o correo electrónico*</span>
                            <Field name="username" className={errors.username && touched.username ? "border-red": "border-blue"} type="text" />
                            <ErrorMessage className="texto-error" component="span" name="username"/>

                            <span className="texto-subs">Contraseña *</span>
                            <Field name="password" className={errors.password && touched.password ? "border-red": "border-blue"} type="password" onFocus={handleFocus} onBlur={handleBlur} onKeyPress={keyPressed}/>
                            <ErrorMessage className="texto-error" component="span" name="password"/>

                            
                            <div className="row justify-content-center text-center">
                                <a className="color-green font-weight-600 olvidar-contra" onClick={handleForgotPassword}><p>Olvidé mi contraseña o recuperar mi cuenta</p> </a>
                            </div>

                            <div className="row justify-content-center">
                                <a onClick={handleSubmit} className="btn btn-azul"> Ingresar </a>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>

            <div className="col-md-6 line-green padding-50">
                <div className="text-center col-md-12 margin-bottom-3">
                    <span className="color-blue titulo1">Unirme a </span>
                    <span className="color-green titulo1">Chappsy</span>   
                </div>
                <Formik initialValues= {inicialValueRegister} validationSchema={registerValidationSchema} onSubmit={handleRegister} validateOnBlur={false}>
                {({ values,  errors, touched, handleSubmit, formik }) => 
                ( 
                <Form onSubmit={handleSubmit}>

                    <OnSubmitValidationError callback={handleSubmitError} />

                    <span className="texto-subs">Nombre completo del usuario *</span>
                    <Field name="name" className={errors.name && touched.name ? "border-red": "border-blue"} type="text" />
                    <ErrorMessage component="span" className="texto-error" name="name"/>

                    <span className="texto-subs">Usuario *</span>
                    <Field name="username" className={errors.username && touched.username ? "border-red": "border-blue"} type="text" />
                    <ErrorMessage component="span" className="texto-error" name="username"/>

                    <span className="texto-subs">Teléfono*</span>
                    <PhoneField name="phone" className={touched.phone && errors.phone ? "border-red": "border-blue"} type="text" />
                    <ErrorMessage component="span" className="texto-error" name="phone" />

                    <span className="texto-subs">Correo electrónico *</span>
                    <Field name="email" className={errors.email && touched.email ? "border-red": "border-blue"} type="text" />
                    <ErrorMessage component="span" className="texto-error" name="email"/>

                    <span className="texto-subs">Confirmar correo *</span>
                    <Field name="confirm_email" className={errors.confirm_email && touched.confirm_email ? "border-red": "border-blue"} type="text" />
                    <ErrorMessage component="span" className="texto-error" name="confirm_email"/>

                    <span className="texto-subs">Contraseña *</span>
                    <Field name="password" className={errors.password && touched.password ? "border-red": "border-blue"} type="password" />
                    <ErrorMessage component="span" className="texto-error" name="password"/>

                    <span className="texto-subs">Confirmar contraseña *</span>
                    <Field name="confirm_password" className={errors.confirm_password && touched.confirm_password ? "border-red": "border-blue"} type="password" />
                    <ErrorMessage component="span" className="texto-error" name="confirm_password"/>

                    <div className="row justify-content-center">
                        <a onClick={handleSubmit} className="btn btn-azul"> Continuar </a>
                    </div>
                </Form>
                )}
                </Formik>
            </div>

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
};

export default AuthOrRegister;