import React, {useState} from "react";

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Home from "./pages/home/home";
import SubscriptionWizard from "./pages/subscription/subscription";
import Terms from "./pages/terms/terms";
import DomainFinder from "./pages/domain/domain";
import HasPreviusDomain from "./pages/domain/hasPreviusDomain";
import Demo from "./pages/demo/demo";
import DemoExito from "./pages/demo/demoExito";
import DemoConfirmCode from "./pages/demo/demoConfirmCode";
import NavBar from "./components/NavBar/navbar";
import Footer from "./components/footer/footer";
import {GlobalStateProvider} from "./hooks/useGlobalState";

export default function App() {

    localStorage.clear();
  
    return (
        <GlobalStateProvider>
            <Router basename={''}>
                <div className="container">
                    <NavBar></NavBar>
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route exact path="/domainFinder">
                            <DomainFinder />
                        </Route>
                        <Route exact path="/selectOwnDomain">
                            <HasPreviusDomain />
                        </Route>
                        <Route path="/subscription/:id">
                            <SubscriptionWizard />
                        </Route>
                        <Route path="/demo">
                            <Demo />
                        </Route>
                        <Route path="/demoConfirmCode">
                            <DemoConfirmCode />
                        </Route>
                        <Route path="/demoExito">
                            <DemoExito />
                        </Route>
                        <Route path="/terms">
                            <Terms />
                        </Route>
                    </Switch>
                </div>
                <Footer></Footer>
            </Router>
        </GlobalStateProvider>
    );
}