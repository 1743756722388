import React, {useState, useRef, useEffect} from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useGlobalState } from "../../hooks/useGlobalState";
import * as Yup from 'yup';
import subscriptionApi from '../../api/subscriptionService';
import SelectDepartament from "../../components/CountrySelect/DepartamentSelect";
import SelectTownship from "../../components/CountrySelect/TownshipSelect";

//imports del dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import OnSubmitValidationError from "../../hooks/formikOnSubmitValidationError";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import PhoneField from "../../components/phoneInput/phoneInput";

const validDocTypes = ["image/png", "image/jpeg", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));


const subscriptionService = subscriptionApi(window.runConfig.apiUrl);

const SignupForm = () => {

    let token = localStorage.getItem('accessToken') || "";
    let domainSelect = localStorage.getItem('domainSelect') || "";
    let hasPreviusDomain = localStorage.getItem('hasPreviusDomain') || "";
    let history = useHistory();
    let { id } = useParams();
    
    useEffect(() => {

        if(domainSelect === "" && hasPreviusDomain == ""){
            history.push(`/subscription/${id}/domainFinder`);
        }

        if(token === ""){
            history.push(`/subscription/${id}/auth`);
        }

        handleNext();
    }, []);
   

    const handleNext = async () => {

        let values = {
            businessName: '',
            tradename: '',
            storeName: '',
            legalRepresentative: '',
            nit: '',
            address: '',
            landline: '',
            cellPhone: '',
            productsTypes: '',
            deparment: 'Guatemala',
            township: 'Guatemala',
            persontype: 'Individual',
            contact: {
                name: '',
                email: '',
                phone: '',
                cellphone: '',
                job: ''
            }
        };

        let registerData = JSON.stringify({...values, plan: id, docs: []});
        localStorage.setItem("storeData", registerData);
        history.push(`/subscription/${id}/step2`);
    }

   
    return (
        <div>                            
        </div>
    );
};

export default SignupForm;