import React, {useState, useEffect} from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import OnSubmitValidationError from "../../hooks/formikOnSubmitValidationError";
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import AuthApi from "../../api/authApi";
import { useGlobalState } from "../../hooks/useGlobalState";
import { makeStyles } from '@material-ui/core/styles';
import jwt from 'jwt-decode';
import "./auth.css";

const useStyles = makeStyles(theme => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AuthOrRegister = () => {
    
    const classes = useStyles();
    let history = useHistory();
    let { id } = useParams();
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const authService = AuthApi(window.runConfig.apiUrl);
    
    const globalState = useGlobalState();

    const errorCallback = (error) => {
        setOpen(false);
        setErrorMessage(error.error);
        setOpenAlert(true);
    }

    const handleConfirmCode = ({confirmCode}) => {

        setOpen(true);
        let tempAuthData = localStorage.getItem("tempAuthData");
        tempAuthData = JSON.parse(tempAuthData);
        
        authService.confirmCode({
            code: confirmCode,
            username: tempAuthData.username
        }).then(
            (result) => {
                console.log(result, "result confirm code");
                authService.login({username: tempAuthData.username, password: tempAuthData.password})
                    .then(
                        resultLogin => {
                            const user = jwt(resultLogin.IdToken);
                            globalState.setUser({data: user, tokens: resultLogin});
                            localStorage.setItem('accessToken', resultLogin.AccessToken);
                            localStorage.setItem("tempAuthData", null);
                            setOpen(false);
                            history.push(`/subscription/${id}/step1`);
                        },
                        error => {
                            if(error === "Incorrect username or password."){
                                localStorage.setItem("tempAuthData", null);
                                history.push(`/subscription/${id}/auth`);
                            }
                        }
                    )
            },
            errorCallback
        );
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlert(false);
    };

    const handleSubmitError = ({errors}) => {
        setErrorMessage("Hay algunos errores en el formulario");
        setOpenAlert(true);
    }

    const inicialValue = {
        'confirmCode': ''
    }

    const ValidationSchema = Yup.object(
    {
        confirmCode: Yup.string()
            .required('El código de confirmación es requerido'),
    });


    let token = localStorage.getItem('tempAuthData') || "";
    let signUpMail = localStorage.getItem('signUpMail') || "";

    useEffect(() => {
        if(token === ""){
            history.push(`/subscription/${id}/auth`);
        }
    });
   
    return (
        <div className="col-md-12 text-center padding-top-18">
            <div className="col-md-12 col-sm-12  margin-bottom-3">
                <span className="color-blue titulo1">Ingresa el código de </span>
                <span className="color-green titulo1">registro:</span>
            </div>
            
            <Formik initialValues= {inicialValue} validationSchema={ValidationSchema} onSubmit={handleConfirmCode}>   
                {({ values,  errors, touched, handleSubmit, formik }) => 
                ( 
                    <Form onSubmit={handleSubmit}>

                        <OnSubmitValidationError callback={handleSubmitError} />

                        {signUpMail === ''? <div></div> : <div className="texto-general margin-bottom-3">Hemos enviado un código de confirmación a: {signUpMail}</div>}

                        <div className="col-md-12 codigo-error margin-bottom-3">
                            <Field name="confirmCode" className={errors.confirmCode && touched.confirmCode ? "border-red": "border-blue"} type="text" />
                            <ErrorMessage className="texto-error text-error-codigo" component="span" name="confirmCode"/>
                        </div>
                        <div className="row justify-content-center">
                            <a href="# " onClick={handleSubmit} className="btn btn-azul"> Continuar </a>
                        </div>
                    </Form>
                )}
            </Formik>


            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
};

export default AuthOrRegister;