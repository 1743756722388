import React, {useState, useEffect, useRef} from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import OnSubmitValidationError from "../../hooks/formikOnSubmitValidationError";
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import SubsApi from "../../api/subscriptionService";
import AuthApi from "../../api/authApi";
import { useGlobalState } from "../../hooks/useGlobalState";
import { makeStyles } from '@material-ui/core/styles';
import jwt from 'jwt-decode'
import "./demo.css";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneField from "../../components/phoneInput/phoneInput";

const useStyles = makeStyles(theme => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Demo = () => {

	const classes = useStyles();
    let history = useHistory();
    let { id } = useParams();
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [subdomain, setSubdomain] = useState("");
    const [subdomainAvailbable, setSubdomainAvailbable] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
	const subscriptionService = SubsApi(window.runConfig.apiUrl);
	const authService = AuthApi(window.runConfig.apiUrl);
    const recaptchaRef = React.createRef();


    useEffect(() => {
        window.scrollTo(0, 0);
	}, []);

	const handleRegister = ({name, username, email, password, store, cellphone}) => {


        let phoneClear = cellphone.replace('-', '');

        let data = { name, username, email, password, subdomain: store, phone: `+502${phoneClear}`};

        setOpen(true);

        localStorage.setItem('tempDemoStoreData', JSON.stringify(data));

        authService.signUp(data).then(
            (result) => {
                setOpen(false);
                history.push(`/demoConfirmCode`);
            },

            (error) => {
                setOpen(false);
                setErrorMessage(error.error);
                setOpenAlert(true);
            }
        );
            
        /* subscriptionService.requestDemo(data).then(
            (result) => {
				setOpen(false);
                history.push(`/demoExito`);
            },

            (error) => {
                setOpen(false);
                setErrorMessage(error.error);
                setOpenAlert(true);
            }
        ); */
    }


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlert(false);
    };

    const handleSubmitError = ({errors}) => {
        setErrorMessage("Hay algunos errores en el formulario");
        setOpenAlert(true);
    }

	const inicialValueRegister = {
		'store': '',
        'name': '',
        'email': '',
        'confirm_email': '',
        'username': '',
        'password': '',
        'confirm_password': '',
        'cellphone': ''
    }

    const validationStatus = (status, subdomain) => {
        setSubdomainAvailbable(status);
        setSubdomain(subdomain);
    }

    const AvailableSubdomain = () => {

        if(subdomain == "" || subdomain == undefined){
            return (<p className="texto-general">Subdominio {subdomainAvailbable? '': 'no'} disponible: <span className="titulo3">tutienda.chappsy.com</span></p>);
        }

        return (<p className="texto-general">Subdominio {subdomainAvailbable? '': 'no'} disponible: <span className="titulo3">{subdomain}.chappsy.com</span></p>);
    }

    const registerValidationSchema = Yup.object(
        {
			store: Yup.string()
                .required('Campo requerido')
                .test('notDots', 'Caracteres especiales no permitidos', function (value) {
                    return !(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g.test(value));
                })
                .test('checkDuplDomain', 'Subdominio en uso', function (value) {
                    return new Promise((resolve, reject) => {
                        subscriptionService.isValidSubdomain(value)
                            .then((result) => {
                                validationStatus(!result, value);
                                return resolve(!result)
                            })
                    })
                })
				.min(4, "Mínimo 8 caracteres"),
            name: Yup.string()
                .required('Campo requerido'),
            username: Yup.string()  
                .required('Campo requerido')
                .min(8, "Mínimo 8 caracteres")
                .test('notDots', 'Caracteres especiales no permitidos', function (value) {
                    return !(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g.test(value));
                })
                .test('checkDuplUsername', 'Nombre de usuario en uso', function (value) {
                    return new Promise((resolve, reject) => {
                        authService.isUsed('username', value)
                            .then((result) => {
                                return resolve(result)
                            })
                    })
                }),
            email: Yup.string()
                .test('checkDuplEmail', 'Email en uso', function (value) {
                    return new Promise((resolve, reject) => {
                        authService.isUsed('email', value)
                            .then((result) => resolve(result))
                    })
                })
                .required('Campo requerido'),
            confirm_email: Yup.string()
                .oneOf([Yup.ref('email'), null], 'Los correos deben coincidir'),
            password: Yup.string()
                .min(6, 'La clave debe ser minimo de 6 caracteres')
                .required('Campo requerido'),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Las claves deben coincidir'),
            cellphone: Yup.string()
                .min(9, "Debe ingresar un teléfono válido")
                .required('Campo requerido'),
		}
	);

    return (
        <div className="row margin-bottom padding-top-18 padding-formulario" >
            <div className="col-md-12 col-sm-12 margin-bottom-3 text-center">
				<span className="color-blue titulo1">Activa tu </span>
				<span className="color-green titulo1">tienda</span>	
			</div>

			<Formik initialValues= {inicialValueRegister} validationSchema={registerValidationSchema} onSubmit={handleRegister}>
                {({ values,  errors, touched, handleSubmit, formik }) => 
                ( 
                <Form onSubmit={handleSubmit}>

                    <OnSubmitValidationError callback={handleSubmitError} />

					<div className="col-md-12 margin-bottom-1">
                        <div>
                            <div className="col-md-12 margin-left">
                                <p className="texto-general"><span className="titulo2">Escribe el nombre de tu tienda</span></p> 
                            </div>
                            <div className="dominio-search">
                                <Field name="store" placeholder="Ejemplo: tutienda" className={errors.store && touched.store ? "border-red texto-general": "border-blue texto-general"} type="text"/>
                                <img className="img-lupa" src={process.env.PUBLIC_URL + '/images/lupa.png'} alt="chappsy" />
                            </div>
                        </div>
						<div className="contenedor-error-message">
				            <ErrorMessage component="span" className="texto-error" name="store"/>
                        </div>
					</div>

                    <div className="col-md-12">
						<AvailableSubdomain></AvailableSubdomain> 
                    </div>

					<div className="row margin-0">
						<div className="col-md-6 col-sm-12">
							<span className="texto-subs">Nombre completo del usuario *</span>
							<Field name="name" className={errors.name && touched.name ? "border-red": "border-blue"} type="text" />
                    		<ErrorMessage component="span" className="texto-error" name="name"/>
						</div>
						<div className="col-md-6 col-sm-12">
							<span className="texto-subs">Usuario *</span>
							<Field name="username" className={errors.username && touched.username ? "border-red": "border-blue"} type="text" />
                    		<ErrorMessage component="span" className="texto-error" name="username"/>
						</div>
						<div className="col-md-6 col-sm-12">
							<span className="texto-subs">Correo electrónico *</span>
							<Field name="email" className={errors.email && touched.email ? "border-red": "border-blue"} type="text" />
                    		<ErrorMessage component="span" className="texto-error" name="email"/>
						</div>
						<div className="col-md-6 col-sm-12">
							<span className="texto-subs">Confirmar correo *</span>
							<Field name="confirm_email" className={errors.confirm_email && touched.confirm_email ? "border-red": "border-blue"} type="text" />
                    		<ErrorMessage component="span" className="texto-error" name="confirm_email"/>
						</div>
						<div className="col-md-6 col-sm-12">
							<span className="texto-subs">Contraseña *</span>
							<Field name="password" className={errors.password && touched.password ? "border-red": "border-blue"} type="password" />
							<ErrorMessage component="span" className="texto-error" name="password"/>
						</div>
						<div className="col-md-6 col-sm-12">
							<span className="texto-subs">Confirmar contraseña *</span>
							<Field name="confirm_password" className={errors.confirm_password && touched.confirm_password ? "border-red": "border-blue"} type="password" />
                    		<ErrorMessage component="span" className="texto-error" name="confirm_password"/>
						</div>
                        <div className="col-md-6 col-sm-12">
                            <span className="texto-subs">Teléfono *</span>
                            <PhoneField name="cellphone" className={touched.cellphone && errors.cellphone ? "border-red": "border-blue"} type="text" />
                            <ErrorMessage component="span" className="texto-error" name="cellphone" />
                        </div>

						<div className="col-md-12">
							{/*aqui falta meter unos const que salen en el home, no los coloque porque no estaba segura de cual era*/}
							<div className="float-right ">
								<ReCAPTCHA
									ref={recaptchaRef}
									sitekey="6LcN1-0UAAAAAGoWXKVIOi_1lQVuhw-TxXsB61BR"
								/>
							</div>
						</div>
						<div className="col-md-12 margin-top-2">
							<a href="#" className="btn btn-principal float-right" onClick={handleSubmit} >Crear tienda</a>
						</div>
					</div>
                </Form>
                )}
            </Formik>
			<Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="error">
					{errorMessage}
				</Alert>
			</Snackbar>
			<Backdrop className={classes.backdrop} open={open}>
				<CircularProgress color="inherit" />
			</Backdrop>
        </div>
    );
}

export default Demo;