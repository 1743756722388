import React, { Component } from "react";
import Slider from "react-slick";
import "./mainCarousel.css";

export default class SimpleSlider extends Component {
    render() {

        const settings = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        return (
            <Slider {...settings}>
                <div className="container row-flex-carousel">
                    <div className="col-md-8 col-sm-12">
                        <img className="img-general" src={process.env.PUBLIC_URL + '/images/carousel1.png'} alt="personaliza"/>
                    </div>
                    <div className="col-md-4 col-sm-12 text-justify responsive-top-3">
                        <span className="color-fucsia titulo2">1. Personaliza tu tienda</span>
                        <p className="texto-general">Ingresa a tu panel de administración y selecciona una plantilla para tu tienda en línea.</p>
                        <p className="texto-general">Puedes personalizar tu tienda con los colores y logotipo de tu marca.</p>
                        <p className="texto-general">Personaliza tus contactos y tus redes sociales.</p>
                        <p className="texto-general">Crea las categorías de productos que quieras para tu tienda.</p>
                    </div>
                </div>
                <div className="row row-flex-carousel">
                    <div className="col-md-8 col-sm-12">
                        <img className="img-general" src={process.env.PUBLIC_URL + '/images/carousel2.png'} alt="selecciona"/>
                    </div>
                    <div className="col-md-4 col-sm-12 text-justify responsive-top-3">
                        <span className="color-fucsia titulo2">2. Selecciona la categoría</span>
                        <p className="texto-general">Puedes elegir la categoría donde quieras subir tus productos asignándola a una sección específica de tu tienda.</p>
                        <p className="texto-general">Cada categoría cuenta son subcategorías para especificar más tus productos.</p>
                    </div>
                </div>
                <div className="row row-flex-carousel">
                    <div className="col-md-8 col-sm-12">
                        <img className="img-general" src={process.env.PUBLIC_URL + '/images/carousel3.png'} alt="sube"/>
                    </div>
                    <div className="col-md-4 col-sm-12 text-justify responsive-top-3">
                        <span className="color-fucsia titulo2">3. Sube tus productos</span>
                        <p className="texto-general">Puedes agregar los detalles tu producto: nombre, peso, subcategoría, sección, cantidad de existencias, tamaño, modelo, año, descripción, etc.</p>
                        <p className="texto-general">Para cada producto puedes agregar hasta 15 fotografías.</p>
                    </div>
                </div>
                <div className="row row-flex-carousel">
                    <div className="col-md-8 col-sm-12">
                        <img className="img-general" src={process.env.PUBLIC_URL + '/images/carousel4.png'} alt="visualiza"/>
                    </div>
                    <div className="col-md-4 col-sm-12 text-justify responsive-top-3">
                        <span className="color-fucsia titulo2">4. Visualiza tus productos en tu tienda</span>
                        <p className="texto-general">Carga todos tus productos y visualízalos en tu tienda inmediatamente.</p>
                        <p className="texto-general">¡Ahora ya podrás dar a conocer tu tienda y generar más ventas!</p>
                    </div>
                </div>
                <div className="row row-flex-carousel">
                    <div className="col-md-8 col-sm-12">
                        <img className="img-general" src={process.env.PUBLIC_URL + '/images/carousel5.png'} alt="visualiza"/>
                    </div>
                    <div className="col-md-4 col-sm-12 text-justify responsive-top-3">
                        <span className="color-fucsia titulo2">5. Visualiza tu consola de reportes</span>
                        <p className="texto-general">Puedes ver de una forma rápida un resumen de tus reportes de ventas y productos cargados en tu tienda.</p>
                    </div>
                </div>
                <div className="row row-flex-carousel">
                    <div className="col-md-8 col-sm-12">
                        <img className="img-general" src={process.env.PUBLIC_URL + '/images/carousel6.png'} alt="visualiza"/>
                    </div>
                    <div className="col-md-4 col-sm-12 text-justify responsive-top-3">
                        <span className="color-fucsia titulo2">6. Consulta tus reportes de ventas</span>
                        <p className="texto-general">Puedes generar tus reportes de ventas y ver detalladamente tus transacciones y total de ganancias.</p>
                        <p className="texto-general">Puedes consultar los datos de tus compradores.</p>
                        <p className="texto-general">Puedes descargar tus datos en formato Excel y CSV.</p>
                    </div>
                </div>
            </Slider>
        );
    }
}