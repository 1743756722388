import React, {useState, useRef, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import "../home/home.css";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import subscriptionApi from '../../api/subscriptionService';

const useStyles = makeStyles(theme => ({
	backdrop: {
	  zIndex: theme.zIndex.drawer + 1,
	  color: '#fff',
	},
}));

const Alert = (props) => {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const subscriptionService = subscriptionApi(window.runConfig.apiUrl);

const SelectSubscriptions = () => {

	let history = useHistory();
	const classes = useStyles();
	const [openDialog, setOpenDialog] = useState(false);
	const [selectedSubscription, setSelectedSubscription] = useState(1);
	const [selectedRegimen, setRegimen] = useState('pagosTrimestrales');
	const [open, setOpen] = useState(false);
	const [openAlert, setOpenAlert] = useState(false);
	const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [hasPreviusDomain, setHasPreviusDomain] = useState(false);

	useEffect(() => {
		let hasPreviusDomainVar = localStorage.getItem('hasPreviusDomain') || false;
		console.log(hasPreviusDomainVar, typeof hasPreviusDomainVar);
		setHasPreviusDomain(hasPreviusDomainVar);
	}, [hasPreviusDomain]);
	
	const handleOptionChange = (e) => {
		setRegimen(e.target.value);
	}

	const handleOpenDialog = (id) => {
		setSelectedSubscription(id);
		localStorage.setItem('regimenFiscal', selectedRegimen);
		history.push(`/subscription/${id}`);
        return false;
    }
    const handleDialogOk = (e) => {
		e.preventDefault();
		history.push(`/subscription/${selectedSubscription}`);
		localStorage.setItem('regimenFiscal', selectedRegimen);
        setOpenDialog(false);
    };

    const handleDialogCancel = (e) => {
        e.preventDefault();
        setOpenDialog(false);
    };
    
	const handleSubmitError = ({errors}) => {
		setErrorMessage("Hay algunos errores en el formulario de contacto");
		setOpenAlert(true);
    }
    
	const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlert(false);
	};

	const handleCloseSuccess = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlertSuccess(false);
	};
	
	return (
		<div className="container">
			<div className="row margin-bottom padding-top-18" id="subscripciones">
				<div className="col-md-12 text-center margin-bottom-3">
					<span className="color-blue titulo1">Elije un plan de </span><span className="color-green titulo1">activación</span>
				</div>
				<div className="row">
					<div className="col-md-3 col-sm-6p col-xs-12">
						<div className="card">
							<p className="titulo-meses color-4 wi-responsive margin-bottom-2">1 Mes</p>

							<p className="card-texto margin-0">Incluye</p>
							<p className="card-texto">{hasPreviusDomain === "true"? "Plataforma en dominio propio y soporte personalizado.": "Plataforma, dominio propio y soporte personalizado."}</p>
							<p className="precio color-4">{hasPreviusDomain === "true"? "Q180" : "Q330"}</p>
							<div onClick={() => handleOpenDialog(1)} className="btn btn-principal">Adquirir</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-6p col-xs-12">
						<div className="card">
							<p className="titulo-meses color-blue wi-responsive margin-bottom-2">3 Meses</p>

							<p className="card-texto margin-0">Incluye</p>
							<p className="card-texto">{hasPreviusDomain === "true"? "Plataforma en dominio propio y soporte personalizado.": "Plataforma, dominio propio y soporte personalizado."}</p>
							<p className="precio color-blue">{hasPreviusDomain === "true"? "Q540" : "Q690"}</p>
							<div onClick={() => handleOpenDialog(3)} className="btn btn-principal">Adquirir</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-6p col-xs-12">
						<div className="card">
							<p className="titulo-meses color-fucsia wi-responsive margin-bottom-2">6 Meses</p>

							<p className="card-texto margin-0">Incluye</p>
							<p className="card-texto">{hasPreviusDomain === "true"? "Plataforma en dominio propio y soporte personalizado.": "Plataforma, dominio propio y soporte personalizado."}</p>
							<p className="precio color-fucsia">{hasPreviusDomain === "true"? "Q1,080" : "Q1,230"}</p>
							<div onClick={() => handleOpenDialog(6)} className="btn btn-principal">Adquirir</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-6p col-xs-12">
						<div className="card">
							<p className="titulo-meses color-aqua wi-responsive margin-bottom-2">12 Meses</p>

							<p className="card-texto margin-0">Incluye</p>
							<p className="card-texto">{hasPreviusDomain === "true"? "Plataforma en dominio propio y soporte personalizado.": "Plataforma, dominio propio y soporte personalizado."}</p>
							<p className="precio color-aqua">{hasPreviusDomain === "true"? "Q2,160" : "Q2,310"}</p>
							<div onClick={() => handleOpenDialog(12)} className="btn btn-principal">Adquirir</div>
						</div>
					</div>
				</div>
			</div>

			<Dialog
				open={openDialog}
				onClose={handleDialogCancel}
				scroll="paper"
				aria-labelledby="dialog-title"
				aria-describedby="dialog-description"
				>
				<DialogTitle id="dialog-title">
					<div className="col-md-12 col-sm-12">
						<span className="color-blue titulo2">Pago de </span>
						<span className="color-green titulo2">suscripción</span>
						<p className="texto-general text-justify texto-regimen">Para poder adquirir tu tienda en línea es necesario que tú o tu empresa este registrado en la SAT (12% de IVA) con algunos de los siguientes regímenes, selecciona el tuyo:</p> 
					</div>
				</DialogTitle>
				<DialogContent>
					<div className="col-md-12 col-sm-12">
						<div className="form-check">
							<label className="container-check">
								<span className="texto-general texto-regimen regimen-1">1.-Sujeto a retención definitiva</span>
								<input
								type="radio"
								name="react-tips"
								value="pagosTrimestrales"
								checked={selectedRegimen === 'pagosTrimestrales'}
								onChange={handleOptionChange}
								className="form-check-input regimen-input"
								/>
								<span className="checkmark-check"></span>
							</label>
						</div>
						<div className="form-check">
							<label className="container-check">
								<span className="texto-general texto-regimen regimen-2">2.-Sujeto a pagos trimestrales </span>
								<input
								type="radio"
								name="react-tips"
								value="retencionDefinitiva"
								checked={selectedRegimen === 'retencionDefinitiva'}
								onChange={handleOptionChange}
								className="form-check-input regimen-input"
								/>
								<span className="checkmark-check"></span>
							</label>
							
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<a href="#" onClick={handleDialogOk} className="btn btn-principal">
						Continuar
					</a>
				</DialogActions>
			</Dialog>

			<Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>

			<Snackbar open={openAlertSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
                <Alert onClose={handleCloseSuccess} severity="success">
                    Mensaje enviado con éxito
                </Alert>
            </Snackbar>

            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>                    
		</div>
	)
};

export default SelectSubscriptions;