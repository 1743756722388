import React, { Component } from "react";
import Slider from "react-slick";
import CarouselContent from "./carouselContent";
import "./mainCarousel2.css";

export default class CenterMode extends Component {
    state = {
        activeSlide: 0
    };

    render() {

        const settings = {
            className: "center",
            dots: false,
            centerMode: true,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 5000,
            focusOnSelect: true,
            draggable: true,
            touchMove: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ],
            afterChange: current => this.setState({ activeSlide: current })
        };

        return (
            <div className="col-md-12">
                <Slider {...settings}>
                    <div className="row carousel2">
                        <div className="col-md-12">
                            <img className="img-icono-2" src="https://d1pijeuontnzl1.cloudfront.net/chappsy/carrito.svg" alt="iconos" />
                        </div>
                        <div className="col-md-12">
                            <span>Tu propia tienda en línea</span>
                        </div>
                    </div>
                    <div className="row carousel2">
                        <div className="col-md-12">
                            <img className="img-icono-2" src="https://d1pijeuontnzl1.cloudfront.net/chappsy/computadora+(1).svg" alt="iconos" /> 
                        </div>
                        <div className="col-md-12">
                            <span>Panel de administración de productos</span>        
                        </div>
                    </div>
                    <div className="row carousel2">
                        <div className="col-md-12">
                            <img className="img-icono-2" src="https://d1pijeuontnzl1.cloudfront.net/chappsy/tarjeta.svg" alt="iconos" />
                        </div>
                        <div className="col-md-12">
                            <span>Procesador de pago Visanet</span>
                        </div>
                    </div>
                    <div className="row carousel2">
                        <div className="col-md-12">
                            <img className="img-icono-2" src="https://d1pijeuontnzl1.cloudfront.net/chappsy/dominio.svg" alt="iconos" />
                        </div>
                        <div className="col-md-12">
                            <span>Hosting y certificado SSL</span>
                        </div>
                    </div>
                    <div className="row carousel2">
                        <div className="col-md-12">
                            <img className="img-icono-2" src="https://d1pijeuontnzl1.cloudfront.net/chappsy/bolsadecompras.svg" alt="iconos" />
                        </div>
                        <div className="columna-flex">
                            <span>Variedad de </span>
                            <span>categorías</span>
                        </div>
                    </div>
                    <div className="row carousel2">
                        <div className="col-md-12">
                            <img className="img-icono-2" src="https://d1pijeuontnzl1.cloudfront.net/chappsy/soportetecnico.svg" alt="iconos" />
                        </div>
                        <div className="columna-flex">
                            <span>Soporte </span>
                            <span>técnico</span>
                        </div>
                    </div>
                </Slider>
                <CarouselContent currentSlide={this.state.activeSlide}/>
            </div>
        );
    }
}