import React, {useEffect} from 'react';
import "./demo.css";

const DemoExito = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
	}, []);

    return (
        <div className="row  text-center margin-bottom padding-top-18">
			<div className="col-md-12 margin-bottom-3">
				<span className="color-blue titulo1">Tu tienda ha sido creada </span>
				<span className="color-green titulo1">exitosamente</span>	
			</div>
			
			<div className="col-md-12 margin-bottom-3">
				<p className="texto-general">Para administrar tu tienda ingresa en CES</p> 
            </div>

            <div className="col-md-12 margin-bottom">
				<a className="btn-principal" href="https://ces.chappsy.com/">Clic aquí</a>
            </div>

			<div className="col-md-12">
				<span className="color-blue titulo1">Para soporte </span>
				<span className="color-green titulo1">técnico</span>
				<p className="texto-general">Puedes comunicarte directamente al:<span className="font-weight-600"> PBX 2235-2960 o enviar un correo a: soporte@chappsy.com</span></p> 
			</div>
			<div className="col-md-12 margin-bottom-3">
				<img className="img-asesor" src={process.env.PUBLIC_URL + '/images/asesor.png'} alt="asesor" />
			</div>
        </div>
    );
}

export default DemoExito;