import React, {useState, useEffect} from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import OnSubmitValidationError from "../../hooks/formikOnSubmitValidationError";
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import AuthApi from "../../api/authApi";
import { useGlobalState } from "../../hooks/useGlobalState";
import { makeStyles } from '@material-ui/core/styles';
import jwt from 'jwt-decode'

const useStyles = makeStyles(theme => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AuthOrRegister = () => {
    
    const classes = useStyles();
    let history = useHistory();
    let { id } = useParams();
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const authService = AuthApi(window.runConfig.apiUrl);
    
    const globalState = useGlobalState();

    let thempAuthDataForgot = JSON.parse(localStorage.getItem('tempAuthDataForgot')) || "";

    useEffect(() => {
        if(thempAuthDataForgot === ""){
            history.push(`/subscription/${id}/auth`);
        }
    }, []);

    const handleConfirmForgot = (values) => {

        setOpen(true);
        let thempAuthDataForgot = JSON.parse(localStorage.getItem('tempAuthDataForgot'));
        authService.confirmForgotPassword({...values, ...thempAuthDataForgot}).then(
            result => {
                localStorage.setItem('tempAuthDataForgot', null);
                authService.login({...values, ...thempAuthDataForgot}).then(
                    resultLogin => {
                        setOpen(false);
                        const user = jwt(resultLogin.IdToken);
                        globalState.setUser({data: user, tokens: resultLogin});
                        localStorage.clear('accessToken')
                        localStorage.setItem('accessToken', resultLogin.AccessToken);
                        console.log(user);
                        history.push(`/subscription/${id}/step1`);
                    },
                    error => {
                        setOpen(false);
                        setErrorMessage(error.error);
                        setOpenAlert(true);
                    }
                );
            },
            error => {
                setOpen(false);
                setErrorMessage(error.error);
                setOpenAlert(true);
            }
        )
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlert(false);
    };

    const handleSubmitError = ({errors}) => {
        setErrorMessage("Hay algunos errores en el formulario");
        setOpenAlert(true);
    }

    const inicialValue = {
        'code': '',
        'password': '',
        'confirmPassword':''

    }

    const ValidationSchema = Yup.object(
    {
        code: Yup.string()
            .required('El código de confirmación es requerido'),
        password: Yup.string()
            .min(8, 'La clave debe ser minimo de 8 caracteres')
            .required('Campo requerido'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Las claves deben coincidir'),
    });


    return (
        <div className="col-md-12 text-center padding-top-18">
            <div className="col-md-12 col-sm-12  margin-bottom-3">
                <span className="color-blue titulo1">Recuperar </span>
                <span className="color-green titulo1">contraseña:</span>
            </div>
            <div className="col-md-12">
                <Formik initialValues= {inicialValue} validationSchema={ValidationSchema} onSubmit={handleConfirmForgot}>   
                    {({ values,  errors, touched, handleSubmit, formik }) => 
                    ( 
                        <Form onSubmit={handleSubmit}>
                            <OnSubmitValidationError callback={handleSubmitError} />

                            <div className="texto-general margin-bottom-3">Se ha enviado el código de confirmación al correo: {thempAuthDataForgot.Destination}</div>


                            <div className="columna-flex align-items-center">

                                <div className="col-md-5 col-sm-12">
                                    <span className="texto-subs float-left">Ingresa el código de recuperación *</span>
                                    <Field name="code" className={errors.code && touched.code ? "border-red": "border-blue"} type="text" />
                                    <ErrorMessage className="texto-error" component="span" name="code"/>
                                </div>

                                <div className="col-md-5 col-sm-12">
                                    <span className="texto-subs float-left">Ingresa tu nueva contraseña *</span>
                                    <Field name="password" className={errors.password && touched.password ? "border-red": "border-blue"} type="password" />
                                    <ErrorMessage className="texto-error" component="span" name="password"/>
                                </div>


                                <div className="col-md-5 col-sm-12">
                                    <span className="texto-subs float-left">Confirma tu nueva contraseña *</span>
                                    <Field name="confirmPassword" className={errors.confirmPassword && touched.confirmPassword ? "border-red": "border-blue"} type="password" />
                                    <ErrorMessage className="texto-error" component="span" name="confirmPassword"/>
                                </div>

                                <div className="row justify-content-center">
                                    <a onClick={handleSubmit} className="btn btn-azul"> Continuar </a>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>


            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
};

export default AuthOrRegister;