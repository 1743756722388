import React from "react";
import { Switch, Route } from "react-router-dom";

import FormularioRegistro from "./formularioRegistro";
import SelectSuscription from "./selectsuscriptions";
import DomainFinder from "../domain/domain";
import PagoSubscripcion from "./pagoSubscripcion";
import ResumenCompra from "./resumenCompra";
import AuthOrRegister from "../auth/authOrRegister";
import ConfirmCode from "../auth/confirmCode";
import ConfirmForgotPassword from "../auth/confirmForgotPassword";
import ForgotPassword from "../auth/forgotPassword";
import "./subscription.css";

const SubscriptionForm = () => {
  
    return (
        <div className="margin-bottom">
            <Switch>
                <Route path="/subscription/select" component={SelectSuscription}>
                </Route>
                <Route path="/subscription/:id/domainFinder" component={DomainFinder}>
                </Route>
                <Route path="/subscription/:id/auth" component={AuthOrRegister}>
                </Route>
                <Route path="/subscription/:id/confirmCode" component={ConfirmCode}>
                </Route>
                <Route path="/subscription/:id/forgotPassword" component={ForgotPassword}>
                </Route>
                <Route path="/subscription/:id/confirmForgotPassword" component={ConfirmForgotPassword}>
                </Route>
                <Route path="/subscription/:id/step2" component={PagoSubscripcion}>
                </Route>
                <Route path="/subscription/:id/step3" component={ResumenCompra}>
                </Route>
                <Route path="/subscription/:id" component={FormularioRegistro}>
                </Route>
            </Switch>
        </div>
    );
  }

export default SubscriptionForm;