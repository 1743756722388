import React, { createContext, useReducer, useContext } from 'react';

/* Action Types */
const loginUser = 'loginUser';
const logOutUser = 'logOutUser';
const updateScroll = 'updateScroll';

/* Define a context and a reducer for updating the context */
const GlobalStateContext = createContext();

const initialState = {
  user: {
    data: null,
    tokens: null
  },
  position: ""
};

const globalStateReducer = (state, action) => {
  switch (action.type) {
    case loginUser:
      return {
        ...state,
        user: { ...action.payload },
      };

    case logOutUser:
      return {
        ...state,
        user: { data: null, tokens: null}
      }

    case updateScroll:
      return {
        ...state,
        position: action.payload
      }

    default:
      return state;
  }
};

/* Export a component to provide the context to its children. This is used in our _app.js file */

export const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    globalStateReducer, 
    initialState
  );

  return (
    <GlobalStateContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalStateContext.Provider>
  );
};

/* 
Default export is a hook that provides a simple API for updating the global state. 
This also allows us to keep all of this state logic in this one file
*/

export const useGlobalState = () => {
  const [state, dispatch] = useContext(GlobalStateContext);

  const setUser = ({ data,tokens }) => {
    dispatch({ 
      type: loginUser, 
      payload: { 
        data,
        tokens
      } 
    });
  };

  const unsetUser = () => {
    dispatch({ 
      type: logOutUser
    });
  };

  const setScroll = (newPosition) => {
    dispatch({ 
      type: updateScroll, 
      payload: newPosition
    });
  };

  return {
    setUser,
    unsetUser,
    setScroll,
    user: { ...state.user },
    position: state.position
  };
};

export default useGlobalState;