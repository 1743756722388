import React, {useEffect} from "react";

const Terms = () => {
	
	useEffect(() => {
        window.scrollTo(0, 0);
	}, []);
	
	return (
    <div>
        <div className="row text-center margin-bottom padding-top-18" id="tutienda">
			<div className="col-md-12 col-sm-12 margin-bottom-3">
				<span className="color-blue titulo1">Términos y </span>
				<span className="color-green titulo1">Condiciones</span>	
			</div>
			<div className="col-md-12 text-justify texto-general">
				<p>
					En los siguientes Términos y Condiciones, manifiesta que el contratante del
					servicio en adelante llamado &quot;EL SUSCRIPTOR&quot;, adquiere los servicios de la
					entidad TRAFICO Y TRANSACCIONES SOCIEDAD, ANÓNIMA, en adelante
					llamada &quot;CHAPPSY&quot; con la finalidad de utilizar un software como servicio (SAAS)
					a través del cual podrá adquirir el servicio de creación de tiendas en línea y
					herramientas para ayudarle a vender bienes y servicios a los compradores, por
					medio de una plataforma digital propia a la cual el suscriptor tendrá acceso desde
					el momento en que se realice la contratación formalmente, habiendo aplicado a
					todos los requisitos correspondientes. (ver listado de requisitos en
					www.chappsy.com).
				</p>

				<p>
					En estos Términos y Condiciones incluyen todos los servicios que ofrece Chappsy
					y cualquier función o herramienta nueva que se agregue a los servicios actuales
					de Chappsy, también estará sujeta a estos Términos y Condiciones.
				</p>

				<p>
					Chappsy se reserva el derecho de actualizar y modificar los Términos y
					Condiciones publicando actualizaciones y cambios en el sitio web oficial
					www.chappsy.com. Se recomienda que los consulte los para que se mantenga
					informado sobre cualquier actualización o cambio que se realice.
				</p>
				<p>
					Al llevar a cabo una contratación se establecerá una relación contractual directa y
					en firme con la empresa Tráfico y Transacciones, S.A. y se considerará que el
					cliente acepta expresamente estos Términos y Condiciones y que está
					plenamente facultado para realizarla. Es entendido por el suscriptor que al
					contratar el servicio está únicamente obteniendo una licencia para su utilización y
					no la propiedad del producto.
				</p>
				<p>
					<strong>
					Sobre la contratación
						</strong>
				</p>
				<p>
					La contratación del servicio se realizará acorde a los precios que se encuentren
					publicados en el sitio www.chappsy.com. El suscriptor podrá efectuar la
					compra del servicio mediante tarjeta de crédito o débito por medio de un
					procesador de pago implementado en la plataforma. Previo a realizar dicha
					contratación el suscriptor deberá crear una cuenta personal con sus datos y llenar
					toda la información requerida en el formulario de registro, habiendo leído y
					aceptado previamente los Términos y Condiciones de compra de alguno de los
					paquetes disponibles de suscripción.
				</p>
				<p>
					<strong>
					Entrega del servicio
						</strong>
				</p>
				<p>
					El servicio adquirido por el suscriptor comprende la licencia de uso de la
					plataforma SaaS contratada, el registro de dominio anual, certificado de seguridad
					SSL anual, alojamiento en servidores seguros y la activación del panel de
					administración CES (Chappsy Ecommerce Services) para la administración y la
					gestión de contenido de la tienda.
				</p>
				<p>
					<strong>
					Gestión de contenido
						</strong>
				</p>
				<p>
					Chappsy no será responsable por el uso y manejo que el suscriptor le dé a la
					tienda en línea, siendo el suscriptor el único responsable de la información
					publicada en dicha tienda como: cantidad de existencias, precio, cualquier tipo de
					característica de cada producto, fotografías del producto propias del suscriptor o
					sujetas a derecho de autor, descripciones del producto, información general de las
					marcas, información de contacto de la tienda y cualquier otra información que el
					suscriptor publique en su tienda desde su panel de administración, haciéndose
					responsable en todo caso de las infracciones que pueda cometer.
				</p>
				<p>
					<strong>
					Solicitud de cancelación del servicio
						</strong>
				</p>
				<p>
					En caso que el suscriptor desee dar por terminada la relación contractual con
					Chappsy después haber sido activada su tienda en línea, podrá hacerlo
					únicamente en un plazo no mayor de 3 días calendario y deberá notificarlo por
					escrito al correo electrónico suscripciones@chappsy.com para que se proceda a la
					inactivación de la tienda en línea. El suscriptor puede realizar una solicitud de
					reembolso de dinero el cuál no podrá ser mayor al 60% del plan contratado,
					Chappsy cobrará un 40% por gastos administrativos por inicialización y
					parametrización de plataforma. En el caso que el cliente de por terminada la
					relación contractual después del plazo de 3 días calendario, Chappsy por ningún
					motivo realizará reembolsos de dinero.
				</p>
				<p>
					El suscriptor será el responsable en el cumplimiento de todas y cada una de las
					regulaciones que exigen las leyes en relación a comercio electrónico y otras leyes
					vigentes, aplicables en cada caso al país de origen de la contratación y deberá
					atender las prohibiciones que establezcan las entidades bancarias o empresas de
					procesamiento de pago para el procesamiento de las ventas por medio de la
					tienda en línea, así como del cumplimiento de todas y cada una de las normas
					legales en materia de impuestos.
				</p>
				<p>
					<strong>
					Cancelación del servicio por penalización
						</strong>
				</p>
				<p>
					Chappsy se reserva el derecho de cancelar totalmente el servicio contratado por el
					suscriptor bajo los siguientes términos:
				</p>
				<p>
					Que el suscriptor propietario y responsable de la tienda en línea incumpla con
					alguno de los requisitos explícitos en www.chappsy.com o incumpla con el pago
					de parcial o total de cualquier servicio o herramienta proporcionada por Chappsy.
					Que el suscriptor propietario y responsable de la tienda en línea, publique
					contenido inapropiado como fotografías y videos pornográficos, contenido con
					lenguaje obsceno, o cualquier tipo de material pornográfico y violento.
				</p>
				<p>
					<strong>
					Pago del servicio
						</strong>
				</p>
				<p>
					El suscriptor deberá realizar el pago por la suscripción inicial en el sitio oficial
					www.chappsy.com, contratando cualquiera de los paquetes disponibles en ese
					momento. La contratación por la renovación del plan o contratación de una nueva
					tienda deberá realizarla desde su panel de administración con el cual accesa con
					su usuario y contraseña.
				</p>
				<p>
					<strong>
					Modelo de negocio de compra venta
						</strong>
				</p>
				<p>
					Chappsy factura al usuario final en este caso llamado Comprador, la totalidad de
					la compra incluida la comisión de cada producto, (el cobro total que se hacer por
					medio de procesador de Visanet). Es decir que, Chappsy adquiere el producto del
					suscriptor siendo Chappsy un comprador directo de los productos que cada
					suscriptor sube en su tienda desde su panel de administración y se rige según el
					precio que este le asigne a cada producto, identificado como “Precio de venta a
					Chappsy”.
				</p>
				<p>
					<strong>
					Cobro de comisión de Chappsy
						</strong>
				</p>
				<p>
					Chappsy cobrará un 11% sobre el total del precio del producto que el suscriptor
					ingrese desde su panel de administración identificado como “Precio de venta a
					Chappsy”. Al momento de contratar el servicio de Chappsy, el suscriptor acepta
					libre y expresamente el cobro de esta comisión. Chappsy se reserva el derecho de
					aplicar un cobro de comisión adicional al usuario final en este caso llamado
					usuario comprador.
				</p>
				<p>
					<strong>
					Reclamo del suscriptor por ventas realizadas
						</strong>
				</p>
				<p>
					El suscriptor se compromete a emitir la factura o las facturas por las ventas que
					realice por medio de su tienda en línea y deberá emitirla a la razón social Tráfico y
					Transacciones, S.A por concepto de venta de producto, regido por el precio
					ingresado en el panel de administración identificado como “Precio de venta a
					Chappsy”. La factura emitida según el NIT registrado en la cuenta del suscriptor de
					Chappsy y deberá ser sujeta bajo retención definitiva o sujeta a pagos trimestrales
					s y deberá ser entregada a Chappsy de forma física o electrónica acompañada del
					formato de reclamos de ventas, el cual deberá ser descargado desde el panel de
					administración del suscriptor. Este formato deberá estar debidamente firmado y
					sellado para presentarlo juntamente con las facturas correspondientes para
					realizar el proceso de desembolso. La entrega de esta papelería deberá realizarse
					de forma electrónica al correo pagos@chappsy.com o entregarla de manera física
					en 10 calle 6-48 zona 9 de lunes a viernes en horario hábil. Chappsy estará
					realizando los pagos por medio de transferencia bancaria a una cuenta de la
					empresa, la cual deberá ser bajo el mismo nombre de la razón social con el NIT
					registrado en la cuenta de Chappsy del suscriptor. En el caso de ser una persona
					individual las transferencias bancarias se realizarán a una cuenta personal a
					nombre del representante legal. Los desembolsos se realizarán únicamente al
					completar el proceso de reclamo según se especifica anteriormente y se realizará
					en un plazo no mayor de 2 días luego de recibir y revisar la papelería solicitada.
				</p>
				<p>
					<strong>
					Envío de los productos
						</strong>
				</p>
				<p>
					El suscriptor se compromete a realizar la entrega de cada producto vendido por
					medio de la tienda en línea, utilizando su logística interna o por medio de alguna
					empresa de reparto a domicilio de su preferencia. Chappsy por ninguna razón se
					compromete o está obligada a realizar envíos de productos de ningún suscriptor.
				</p>
				<p>
					<strong>
					Políticas de envío y políticas de reclamos, cambios y devoluciones
						</strong>
				</p>
				<p>
					El suscriptor se compromete a incluir en su tienda en línea las políticas de envío,
					reclamos, cambios y devoluciones de productos las cuales deberá cumplir en su
					totalidad sin excepción alguna. Chappsy no se compromete a solventar cualquier
					inconveniente que se presente con algún comprador luego de haber realizado la
					compra.
				</p>
				<p>
					<strong>
					Soporte técnico y asistencia comercial
						</strong>
				</p>
				<p>
					Chappsy cuenta con un departamento de soporte técnico que proporciona ayuda
					al presentarse cualquier inconveniente con el sistema, tienda línea, procesador de
					pago, o cualquier servicio electrónico que haya adquirido el suscriptor. Cualquier
					consulta deberá hacerse por medio del correo electrónico
					soportetecnico@chappsy.com o llamando directamente al PBX: 2235-2960.
				</p>
				<p>
					Chappsy cuenta con un departamento de servicio al cliente el cual estará
					solventando dudas sobre contrataciones del servicio, nuevos planes de servicios,
					forma de uso de cualquier servicio electrónico, formas de pago, desembolsos por
					ganancias y cualquier otro tipo de asistencia comercial. Para cualquier consulta
				</p>
				<p>
					deberá hacerse por medio del correo electrónico servicioalcliente@chappsy.com o
					llamando directamente al PBX: 2235-2960.
 				</p>
			</div>
		</div>
    </div>
)}

export default Terms;