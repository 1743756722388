const subscriptionService = (rootUrl) => {

    return {
        processCheckout: (data) => {
            
            let storeData = localStorage.getItem("storeData") || {};
            storeData = JSON.parse(storeData);
            let regimenFiscal = localStorage.getItem("regimenFiscal") || "";
            let accessToken = localStorage.getItem("accessToken") || "";
            let domainSelect = localStorage.getItem('domainSelect') || false;
            let hasPreviusDomain = localStorage.getItem('hasPreviusDomain') || false;

            let json = {
                "pan": data.cardPan,
                "expDate": data.cardExpDate,
                "cvv2": data.cardCvv,
                "cardholder": data.cardOwner,
                "visacuotas": data.visacuotas,
                "billingInfo": {
                    "name": data.invoiceName,
                    "address": data.invoiceAdress,
                    "nit": data.invoiceNit
                },
                "deliveryInfo": {
                    "phone": storeData.contact.phone,
                    "departamento": storeData.contact.deparment,
                    "municipio": storeData.contact.township,
                    "destino": "Guatemala",
                    "address": storeData.address,
                    "receiver": data.invoiceName,
                    "email": data.invoiceEmail
                },
                "store": {...storeData, regimenFiscal},
                "domain": domainSelect,
                "fixedDomain": "",
                "hasPreviusDomain": hasPreviusDomain === "true"? true : false,
                "plan": data.plan                
            }

            let headers = {
                'Content-Type': 'application/json'
            }

            if(accessToken !== ""){
                headers.accessToken = accessToken;
            }

            return fetch(`${rootUrl}/subscriptions/newSubscription`, 
                {
                    method: 'POST',
                    body: JSON.stringify(json),
                    headers: headers
                }
            ).then(res => {
                return res.json().then(response => {
                    if(response.error){
                        return Promise.reject(response);
                    }
    
                    return Promise.resolve(response);
                })
            })
        },
        saveImage: (file) => {
                    
            let accessToken = localStorage.getItem("accessToken") || "";

            let headers = {
                'accessToken': accessToken
            }

            const fd = new FormData();
            fd.append('picture', file);

            // send `POST` request
            return fetch(`${rootUrl}/pictures`, {
                method: 'POST',
                body: fd,
                headers: headers
            })
            .then(res => res.json())
            .then(json => {
                if(json.error){
                    return Promise.reject(json);
                }

                return Promise.resolve(json);
            });
        },
        sendQuestion: (data) => {
            
            let json = {
                "contactData": data,
            }

            let headers = {
                'Content-Type': 'application/json'
            }

            return fetch(`${rootUrl}/subscriptions/consulta`, 
                {
                    method: 'POST',
                    body: JSON.stringify(json),
                    headers: headers
                }
            ).then(res => {
                return res.json().then(response => {
                    if(response.error){
                        return Promise.reject(response);
                    }
    
                    return Promise.resolve(response);
                })
            })
        },
        requestDemo: (data) => {

            let jsonDemoRequest = {
                "subdomain": data.subdomain,
                "phone": data.phone
            }

            let headers = {
                'Content-Type': 'application/json',
                'accessToken': localStorage.getItem('accessToken')
            }

            return fetch(`${rootUrl}/subscriptions/demo`, 
                {
                    method: 'POST',
                    body: JSON.stringify(jsonDemoRequest),
                    headers: headers
                }
            ).then(res => {
                return res.json().then(response => {
                    if(response.error){
                        return Promise.reject(response);
                    }

                    return Promise.resolve(response);
                })
            })
        },
        isValidSubdomain: (subdomain) => {

            if(subdomain == "" || subdomain == undefined){
                return Promise.resolve(false);
            }

            return fetch(`${rootUrl}/test/domains/subdomain/exists?subdomain=${subdomain}`)
            .then(res => {
                return res.json().then(response => {
                    if(response.error){
                        return Promise.reject(response);
                    }
    
                    return Promise.resolve(response);
                })
            })
        },
        checkAvailability: (domain) => {
            return fetch(`${rootUrl}/test/domains/checkDomainAvailability?uri=${domain}`)
            .then(res => {
                return res.json().then(response => {

                    if(response.error){

                        if(response.error === "Errors: [Give domain name must contain more than 1 label]"){
                            response.error = "El dominio debe tener al menos una etiqueta. Ejemplo '.com'";
                        }

                        return Promise.reject(response);
                    }
    
                    return Promise.resolve(response);
                })
            })
        },
        getSuggestion: (domain) => {
            return fetch(`${rootUrl}/test/domains/getDomainSuggestions?uri=${domain}`)
            .then(res => {
                return res.json().then(response => {

                    if(response.error) {

                        if(response.error === "Errors: [Give domain name must contain more than 1 label]"){
                            response.error = "El dominio debe tener al menos una etiqueta. Ejemplo '.com'";
                        }

                        return Promise.reject(response);
                    }
    
                    return Promise.resolve(response);
                })
            })
        }
    };
}

export default subscriptionService;