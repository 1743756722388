import React, { useState, useEffect } from 'react';
import Cleave from 'cleave.js/react';
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory, useParams } from "react-router-dom";
import { useGlobalState } from "../../hooks/useGlobalState";
import subscriptionApi from '../../api/subscriptionService';
import { makeStyles } from '@material-ui/core/styles';
import VisacuotaSelect from "../../components/VisacuotasSelect/VisacuotasSelect";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

var luhnChk = (function (arr) {
    return function (ccNum) {
        var
            len = ccNum.length,
            bit = 1,
            sum = 0,
            val;

        while (len) {
            val = parseInt(ccNum.charAt(--len), 10);
            sum += ((bit ^= 1)) ? arr[val] : val;
        }

        return sum && sum % 10 === 0;
    };
}([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]));

const LuhnError = (props) => {
    const isValidCard = props.validCard;

    if (isValidCard) {
        return <div>Confirmación (Luhn): <span className="text-tarjeta-date">Aprobada</span></div>;
    }

    return <div>Confirmación (Luhn): <span className="text-tarjeta-date-error">Reprobada</span></div>;
};

const CardPanDigitsStatus = (props) => {

    const pan = props.pan;
    const cardType = props.cardType;

    if (cardType === 'visa' && (pan.length > 15)) {
        return <div>Cantidad de números: <span className="text-tarjeta-date">Válido</span></div>
    }

    if (cardType === 'mastercard' && (pan.length > 15)) {
        return <div>Cantidad de números: <span className="text-tarjeta-date">Válido</span></div>
    }

    return <div>Cantidad de números: <span className="text-tarjeta-date-error">Inválidos</span></div>;
};

const CardStatus = (props) => {
    const isValidCard = props.validCard;

    if (isValidCard) {
        return <div>Estado: <span className="text-tarjeta-date">Válido</span></div>;
    }

    return <div>Estado: <span className="text-tarjeta-date-error">Inválida</span></div>;
};

const subscriptionService = subscriptionApi(window.runConfig.apiUrl);

const ErrorMessage = (props) => {

    let name = props.name;
    let errors = props.errors;
    let touched = props.touched;

    if(errors[name] && touched[name]){
        return <span className={props.className}>{errors[name]}</span>
    }

    return <span></span>
}

const PagoSubscripcion = () => {

    const classes = useStyles();
    let history = useHistory();
    let { id } = useParams();
    const globalState = useGlobalState();
    const user = globalState.user;
    const [expDateCleave, setExpDateCleave] = useState(null);
    const [cardType, setCardType] = useState('');
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [input, setInput] = useState({
        invoiceName: '',
        invoiceNit: '',
        invoiceAdress: '',
        invoiceEmail: '',
        cardOwner: '',
        cardExpDate:'',
        cardCvv: '',
        cardPan: '',
        cardType: '',
        cardValidity: false,
        visacuotas: "VC03",
        plan: id
    })

    const [touched, setTouched] = useState({
        invoiceName: false,
        invoiceNit: false,
        invoiceAdress: false,
        invoiceEmail: false,
        cardOwner: false,
        cardExpDate:false,
        cardCvv: false,
        cardPan: false,
        cardType: false,
    })

    const [errors, setErrors] = useState({
        invoiceName: "",
        invoiceNit: "",
        invoiceAdress: "",
        invoiceEmail: "",
        cardOwner: "",
        cardExpDate:"",
        cardCvv: "",
        cardPan: "",
        cardType: "",
    })

    const visacuotaOnChange = (e) => {
        setInput({...input, visacuotas: e.value });
    }

    const handleInputChange = (e) => {
        return setInput({...input, [e.currentTarget.name]: e.currentTarget.value });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlert(false);
    };

    const handleNext = async () => {

        setOpen(true);
        touchAllFields();
        validateAllForm();

        let validForm = isValidForm();

        if(!validForm){
            setOpen(false);
            setErrorMessage("Hay algunos errores en el formulario");
            setOpenAlert(true);
            return;
        }

        let expDate =  `${input.cardExpDate.substr(2, 4)}${input.cardExpDate.substr(0, 2)}`;

        subscriptionService.processCheckout({...input, visacuotas: false, cardExpDate: expDate, cardType: cardType}).then(
            (result) => {
                setOpen(false);
                localStorage.clear();
                localStorage.setItem('voucher', JSON.stringify(result.voucher));
                history.push(`/subscription/${id}/step3`);
            },

            (error) => {
                setOpen(false);
                setErrorMessage(error.error);
                setOpenAlert(true);
            }
          )
    }

    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const isValidForm = () => {
        for (let prop in errors){
            if(errors[prop] !== false){
                return false;
            }
        }

        return true;
    }

    const validateAllForm = () => {

        let newErrors = {
            ...errors,
            invoiceName: (input.invoiceName === '' ? 'Campo requerido': false),
            invoiceNit: (input.invoiceNit === '' ? 'Campo requerido': false),
            invoiceAdress: (input.invoiceAdress === '' ? 'Campo requerido': false),
            invoiceEmail: (input.invoiceEmail === '' ? 'Campo requerido': false),
            cardOwner: (input.cardOwner === '' ? 'Campo requerido': false),
            cardPan: false,
            cardExpDate:(input.cardExpDate === '' ? 'Campo requerido': false),
            cardCvv: (input.cardCvv === '' ? 'Campo requerido': false),
            cardType: false,
        };

        if(cardType !== 'visa' && cardType !== 'mastercard'){
            newErrors = {...newErrors, cardPan: 'La tarjeta debe ser Visa o Mastercard'};    
        }

        if(input.cardPan === ''){
            newErrors = {...newErrors, cardPan: 'Campo requerido'};    
        }

        setErrors(newErrors);
    }

    const touchAllFields = () => {
        setTouched({
            invoiceName: true,
            invoiceNit: true,
            invoiceAdress: true,
            invoiceEmail: true,
            cardOwner: true,
            cardExpDate: true,
            cardCvv: true,
            cardPan: true,
            cardType: false,
        });
    }

    const handleBlur = (e) => {

        let field = e.target.name;

        if (!touched.field) {
            setTouched({...touched, [field]: true});
        }
    }

    const onCreditCardChange = (event) => {
        setInput({...input, cardPan: event.target.rawValue, cardValidity: luhnChk(event.target.rawValue) });
    }

    const onExpDateInit = cleave => {
        setExpDateCleave(cleave);
    }

    const onChangeExpDate = event => {

        let expDate = event.target.rawValue;

        let actualDate = new Date();
        let actualYear = actualDate.getYear() - 100;
        let inputYear = expDate.substr(expDate.length - 2);

        if (inputYear < actualYear && expDate.length > 3) {
            expDate = expDate.substr(0, 2).concat(actualYear);
            expDateCleave.setRawValue(expDate);
        }

        setInput({...input, cardExpDate: expDate });
    }

    const onChangeCvv = event => {

        const re = /^[0-9\b]+$/;

        if ((event.target.value === '' || re.test(event.target.value))) {
            setInput({...input, cardCvv: event.currentTarget.value });
        }
    }

    const onCreditCardTypeChanged = type => {

        if (type === "unknown") {
            return setCardType("Tarjeta inválida");
        }

        return setCardType(type);
    }

    let token = localStorage.getItem('accessToken') || "";

    useEffect(() => {
        if(token === ""){
            history.push(`/subscription/${id}/auth`);
        }
    }, [user, history, id, token]);

    useEffect(() => {
        validateAllForm();
    }, [input]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="padding-top-18">
            <div className="text-center col-md-12 margin-bottom-3">
                <span className="color-blue titulo1">Pago de </span>
                <span className="color-green titulo1">suscripción</span>   
            </div>

            <div className="row">
                <div className="col-md-12">
                    <p className="titulo2 color-blue">Datos de factura</p>
                </div>
                <div className="col-md-4 col-sm-12 mb-2">
                    <span className="texto-subs">Nombre de la factura *</span>
                    <input type="text" className={touched.invoiceName && errors.invoiceName ? "border-red":"border-blue"} name="invoiceName" onChange={handleInputChange} onBlur={handleBlur}></input>
                    <ErrorMessage className="texto-error" name="invoiceName" touched={touched} errors={errors}/>
                </div>
                <div className="col-md-4 col-sm-12 mb-2">
                    <span className="texto-subs">NIT *</span>
                    <input type="text" className={touched.invoiceNit && errors.invoiceNit ? "border-red":"border-blue"} name="invoiceNit" onChange={handleInputChange} onBlur={handleBlur}></input>  
                    <ErrorMessage className="texto-error" name="invoiceNit" touched={touched} errors={errors}/>                
                </div>
                <div className="col-md-4 col-sm-12 mb-2">
                    <span className="texto-subs">Dirección *</span>
                    <input type="text" className={touched.invoiceAdress && errors.invoiceAdress ? "border-red":"border-blue"} name="invoiceAdress" onChange={handleInputChange} onBlur={handleBlur}></input>
                    <ErrorMessage className="texto-error" name="invoiceAdress" touched={touched} errors={errors}/>
                </div>
                <div className="col-md-4 col-sm-12 mb-2">
                    <span className="texto-subs">Correo electrónico para envío de factura *</span>
                    <input type="text" className={touched.invoiceEmail && errors.invoiceEmail ? "border-red":"border-blue"} name="invoiceEmail" onChange={handleInputChange} onBlur={handleBlur}></input>
                    <ErrorMessage className="texto-error" name="invoiceEmail" touched={touched} errors={errors}/>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <p className="titulo2 color-blue">Datos de tarjeta de crédito o débito</p>
                </div>
                <div className="row">
                    <div className="row struc-1">
                        <div className="col-md-6 col-sm-12 mb-2">
                            <span className="texto-subs">Titular de la tarjeta</span>
                            <input type="text" className={touched.cardOwner && errors.cardOwner ? "border-red":"border-blue"} name="cardOwner" onChange={handleInputChange} onBlur={handleBlur}></input>
                            <ErrorMessage className="texto-error" name="cardOwner" touched={touched} errors={errors}/>
                        </div>
                        <div className="col-md-6 col-sm-12 mb-2">
                            <span className="texto-subs">Número de la tarjeta</span>
                            <Cleave
                                name="cardPan"
                                options={{
                                    creditCard: true,
                                    onCreditCardTypeChanged: onCreditCardTypeChanged,
                                    creditCardStrictMode: true
                                }}
                                onBlur={handleBlur}
                                onChange={onCreditCardChange}
                                className={touched.cardPan && errors.cardPan ? "border-red":"border-blue"}
                            />
                            <ErrorMessage className="texto-error" name="cardPan" touched={touched} errors={errors}/>
                        </div>
                        <div className="col-md-6 col-sm-12 mb-2">
                            <span className="texto-subs">Fecha de caducidad (MM/YY) *</span>
                            <Cleave
                                name="cardExpDate"
                                options={{
                                    date: true,
                                    datePattern: ['m', 'y']
                                }}
                                onChange={onChangeExpDate}
                                onInit={onExpDateInit}
                                className={touched.cardExpDate && errors.cardExpDate ? "border-red":"border-blue"}
                                onBlur={handleBlur}
                            />
                            <ErrorMessage className="texto-error" name="cardExpDate" touched={touched} errors={errors}/>
                        </div>
                        <div className="col-md-6 col-sm-12 mb-2">
                            <span className="texto-subs"> CVC / CVV  *</span>
                            <input type="text" className={touched.cardCvv && errors.cardCvv ? "border-red":"border-blue"} name="cardCvv" onChange={onChangeCvv} value={input.cardCvv} maxLength="4" onBlur={handleBlur}></input>
                            <ErrorMessage className="texto-error" name="cardCvv" touched={touched} errors={errors}/>
                        </div>
                    </div>
                    <div className="row struc-2">
                        <div className="col-md-12 col-sm-12 mb-2 texto-subs columna-flex justify-content-around">
                            {touched.cardPan? <div>Tipo de tarjeta: <span className="text-tarjeta-date">{cardType}</span></div>: ""}
                            {touched.cardPan? <CardStatus validCard={input.cardValidity}></CardStatus>: ""}
                            {touched.cardPan? <CardPanDigitsStatus pan={input.cardPan} cardType={cardType}></CardPanDigitsStatus>: ""}
                            {touched.cardPan? <LuhnError validCard={input.cardValidity}></LuhnError>: ""}
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row botones">
                <div className="col-md-12 text-center margin-btn-special padding-0">
                    <a onClick={handleNext} className="btn btn-azul-20">Comprar</a>
                </div>
            </div>

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>

            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default PagoSubscripcion;