import React, {useEffect} from "react";
import { useHistory } from "react-router-dom";
import * as moment from 'moment';

const ResumenCompra = () => {

    const voucher = (localStorage.getItem('voucher') && JSON.parse(localStorage.getItem('voucher'))) || {};
    let history = useHistory();

    useEffect(() => {
        console.log(voucher);
        if(JSON.stringify(voucher) === "{}"){
            history.push(`/#home`);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
	}, []);

    let Fees = () => {
        if(voucher && voucher.fees){
            return (
                <div className="row justify-content-between">
                    <span className="texto-general"> 
                        <img className="img-icono" src="https://d1pijeuontnzl1.cloudfront.net/chappsy/auditoria.png" alt="iconos" />
                        Visacuotas:
                    </span>
                <span className="texto-general">{voucher.fees}</span>
                </div>
            );
        }
        
        return "";
    };

    return (
        <div className="row padding-top-18">
            <div className="col-md-12 text-center">
                <span className="color-blue titulo1">¡Gracias por tu </span>
                <span className="color-green titulo1">compra!</span>   
            </div>

            <div className="col-md-12 text-center margin-bottom-3">
                <span className="texto-general">Te compartimos un resumen de tu transacción.</span>
            </div>

            <div className="col-md-12 margin-bottom-3 row-flex">
                <div className="col-md-4 col-sm-12">
                    <div className="plan">
                        <p className="titulo2 color-aqua">{voucher && voucher.product && voucher.product.name}</p>
                        <img className="img-logo-plan" src={process.env.PUBLIC_URL + '/images/logo.svg'} alt="logo" />
                    </div>
                </div>
                <div className="col-md-8 col-sm-12 iconos-left-padding">
                    <div className="row justify-content-between">
                        <p className="texto-general font-weight-600 margin-left">Total de la compra:</p>
                        <p className="texto-general font-weight-600 border-bottom-blue">Q{voucher && voucher.product && voucher.product.price}</p>
                    </div>
                    <div className="row justify-content-between">
                        <span className="texto-general"> 
                            <img className="img-icono" src="https://d1pijeuontnzl1.cloudfront.net/chappsy/pago.png" alt="iconos" />
                            Método de pago:
                        </span>
                        <span className="texto-general">{voucher && voucher.method}</span>
                    </div>
                    <div className="row justify-content-between">
                        <span className="texto-general"> 
                            <img className="img-icono" src="https://d1pijeuontnzl1.cloudfront.net/chappsy/email.png" alt="iconos" />
                            Correo electrónico:
                        </span>
                        <span className="texto-general">{voucher && voucher.deliveryInfo && voucher.deliveryInfo.email}</span>
                    </div>
                    <div className="row justify-content-between">
                        <span className="texto-general"> 
                            <img className="img-icono" src="https://d1pijeuontnzl1.cloudfront.net/chappsy/phone.png" alt="iconos" />
                            Télefono de contacto:
                        </span>
                        <span className="texto-general">{voucher && voucher.deliveryInfo  && voucher.deliveryInfo.phone}</span>
                    </div>
                    <div className="row justify-content-between">
                        <span className="texto-general"> 
                            <img className="img-icono" src="https://d1pijeuontnzl1.cloudfront.net/chappsy/user.png" alt="iconos" />
                            Titular de la tarjeta:
                        </span>
                        <span className="texto-general">{voucher && voucher.cardholder}</span>
                    </div>
                    <div className="row justify-content-between">
                        <span className="texto-general"> 
                            <img className="img-icono" src="https://d1pijeuontnzl1.cloudfront.net/chappsy/card.png" alt="iconos" />
                            Número de la tarjeta:
                        </span>
                        <span className="texto-general">{voucher && voucher.card}</span>
                    </div>
                    <div className="row justify-content-between">
                        <span className="texto-general"> 
                            <img className="img-icono" src="https://d1pijeuontnzl1.cloudfront.net/chappsy/referencia.png" alt="iconos" />
                            Código referencia:
                        </span>
                        <span className="texto-general">{voucher && voucher.reference}</span>
                    </div>
                    <div className="row justify-content-between">
                        <span className="texto-general"> 
                            <img className="img-icono" src="https://d1pijeuontnzl1.cloudfront.net/chappsy/afiliacion.png" alt="iconos" />
                            Afiliación:
                        </span>
                        <span className="texto-general">00575123</span>
                    </div>
                    <div className="row justify-content-between">
                        <span className="texto-general"> 
                            <img className="img-icono" src="https://d1pijeuontnzl1.cloudfront.net/chappsy/order.png" alt="iconos" />
                            Referencia bancaria:
                        </span>
                        <span className="texto-general">{voucher && voucher.bankReference}</span>
                    </div>
                    <div className="row justify-content-between">
                        <span className="texto-general"> 
                            <img className="img-icono" src="https://d1pijeuontnzl1.cloudfront.net/chappsy/lock.png" alt="iconos" />
                            Código de autorización:
                        </span>
                        <span className="texto-general">{voucher && voucher.authorizationNumber}</span>
                    </div>
                    <div className="row justify-content-between">
                        <span className="texto-general"> 
                            <img className="img-icono" src="https://d1pijeuontnzl1.cloudfront.net/chappsy/auditoria.png" alt="iconos" />
                            Número de auditoría:
                        </span>
                        <span className="texto-general">{voucher && voucher.auditNumber}</span>
                    </div>
                    <Fees/>
                    <div className="row justify-content-between">
                        <span className="texto-general"> 
                            <img className="img-icono" src="https://d1pijeuontnzl1.cloudfront.net/chappsy/calendario.png" alt="iconos" />
                            Fecha y hora de transacción:
                        </span>
                        <span className="texto-general">{moment.unix((voucher && voucher.create_at)).format("MM/DD/YYYY HH:mm:ss")}</span>
                    </div>
                    <div className="row justify-content-between">
                        <p className="texto-general font-weight-600 margin-left">(1) Pagado electrónicamente</p>
                    </div>
                </div>
            </div>

            <div className="col-md-12 justify-content-center margin-bottom-3">
                <p className="texto-general">Este mensaje fue enviado automáticamente al correo indicado para envío de la factura electrónica.</p>
            </div>

            <div className="col-md-12 text-center margin-bottom-3">
                <span className="color-blue titulo1">¡Te estaremos </span>
                <span className="color-green titulo1">contactando!</span>   
            </div>

            <div className="col-md-12 text-center margin-bottom">
                <p className="texto-general">Un asesor se estará comunicando contigo lo más pronto posible para aperturar tu tienda.</p>  
                <p className="texto-general">Puedes comunicarte directamente al:</p>
                <p className="texto-general font-weight-600">PBX: 2235-2960</p>
                <img className="img-asesor" src={process.env.PUBLIC_URL + '/images/asesor.png'} alt="asesor" />
            </div>
        </div>
    );
}

export default ResumenCompra;