import React, {useRef} from 'react';
import { NavHashLink } from 'react-router-hash-link';
import "./navbar.css";
import { useGlobalState } from "../../hooks/useGlobalState";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

const AuthBar = (props) => {

    const globalState = useGlobalState();
    const user = globalState.user;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogOut = () => {
        setAnchorEl(null);
        globalState.unsetUser();
        localStorage.clear('accessToken')
    }

    if(user.data !== null){

        const name = user.data.name.split(" ")[0]

        return (
            <div className={props.className}>
                <div className="row row-login container">
                    <div className="opccion-login-ces">
                        <span>CES</span><span className="linea-verde"></span>
                    </div>
                    <div className="opccion-login " onClick={handleMenu}>
                        <img className="img-sesion" src="https://chappsybucked.s3.amazonaws.com/chappsy/user.png" alt="user" />{name} 
                        <img className="img-flecha-drop" src={process.env.PUBLIC_URL + '/images/flecha.png'} alt="arrow" />
                    </div>
                    <div className="row">
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem className="opccion-login" onClick={handleLogOut}>
                                <img className="img-sesion" src={process.env.PUBLIC_URL + '/images/candado.png'} alt="sesion" />
                                Cerrar sesión
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={props.className}>
            <div className="row row-login">
                <div className="opccion-login-ces">
                    <a className="" href="https://ces.chappsy.com/" target="_blank">CES</a> <span className="linea-verde"></span>
                </div>
            </div>
        </div>
    );
}

const NavBar = () => {
    
    const globalState = useGlobalState();
    const position = globalState.position;
    const navbarRef = useRef();

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -100; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    const SideNav = () => {
        if(navbarRef && navbarRef.current){
            if(window.pageYOffset > navbarRef.current.getBoundingClientRect().height){
                return (
                    <div className="row menu-lateral-contenedor ocultar-menu-lateral">
                        <div>
                            <div className="col-md-2 progress"></div>
                            <div className="col-md-12">
                                <ul id="menu" className="menu menu-lateral">
                                    <NavHashLink smooth activeClassName="opccion-menu-active" onClick={() => (refNavbarResponsive.current.checked = false)} isActive={() => { return position === "home"}} to="/#home" className="opccion-menu opccion-menu-lateral"  scroll={el => scrollWithOffset(el)}><span className="circulo-menu-lateral"></span>Inicio</NavHashLink>
            
                                    <NavHashLink smooth activeClassName="opccion-menu-active" onClick={() => (refNavbarResponsive.current.checked = false)} isActive={() => { return position === "beneficios"}} to="/#beneficios" className="opccion-menu opccion-menu-lateral"  scroll={el => scrollWithOffset(el)}><span className="circulo-menu-lateral"></span>Beneficios</NavHashLink>
            
                                    <NavHashLink smooth activeClassName="opccion-menu-active" onClick={() => (refNavbarResponsive.current.checked = false)} isActive={() => { return position === "plantillas"}} to="/#plantillas" className="opccion-menu opccion-menu-lateral"  scroll={el => scrollWithOffset(el)}><span className="circulo-menu-lateral"></span>Plantillas</NavHashLink>
            
                                    <NavHashLink smooth activeClassName="opccion-menu-active" onClick={() => (refNavbarResponsive.current.checked = false)} isActive={() => { return position === "tutienda"}} to="/#tutienda" className="opccion-menu opccion-menu-lateral"  scroll={el => scrollWithOffset(el)}><span className="circulo-menu-lateral"></span>Tu tienda</NavHashLink>
            
                                    <NavHashLink smooth activeClassName="opccion-menu-active" onClick={() => (refNavbarResponsive.current.checked = false)} isActive={() => { return position === "comofunciona"}} to="/#comofunciona" className="opccion-menu opccion-menu-lateral"  scroll={el => scrollWithOffset(el)}><span className="circulo-menu-lateral"></span>Cómo funciona</NavHashLink>
            
                                    <NavHashLink smooth activeClassName="opccion-menu-active" onClick={() => (refNavbarResponsive.current.checked = false)} isActive={() => { return position === "planes"}} to="/#planes" className="opccion-menu opccion-menu-lateral" scroll={el => scrollWithOffset(el)}><span className="circulo-menu-lateral"></span>Planes</NavHashLink>
            
                                    <NavHashLink smooth activeClassName="opccion-menu-active" onClick={() => (refNavbarResponsive.current.checked = false)} isActive={() => { return position === "contacto"}} to="/#contacto" className="opccion-menu opccion-menu-lateral"  scroll={el => scrollWithOffset(el)}><span className="circulo-menu-lateral"></span>Contacto</NavHashLink>
                                </ul>                
                            </div>
                        </div>
                    </div>
                );
            }

        }
        return (<div></div>);
    }

    const refNavbarResponsive = useRef();

    return (
    <div className="scroll-navbar">
        <div ref={navbarRef} className="container container-especial">
            <AuthBar className="ocultar"></AuthBar>
            <div className="row row-menu sox">
                <div className="col-md-1 col-sm-12 cont-img-menu img-responsive-navbar">
                    <NavHashLink smooth to="/#home" scroll={el => scrollWithOffset(el)}>
                        <img className="logo-menu logo-menuhome-responsive" src={process.env.PUBLIC_URL + '/images/logo.svg'} alt="logoChappsy" />
                    </NavHashLink>
                </div>
                <div className="col-md-11 row-responsive-user">
                    <div className="col-md-12 col-sm-4 w-4">
                        <div id="menuToggle">
                            <input type="checkbox" className="check-falso" ref={refNavbarResponsive}/>
                            <span></span>
                            <span></span>
                            <span></span>
                            <ul id="menu" className="menu">
                                <NavHashLink smooth to="/#home" onClick={() => (refNavbarResponsive.current.checked = false)} scroll={el => scrollWithOffset(el)}>
                                        <img className="logo-menu-responsive" src={process.env.PUBLIC_URL + '/images/logo.svg'} alt="logoChappsy" />
                                    </NavHashLink>

                                <NavHashLink smooth activeClassName="opccion-menu-active" onClick={() => (refNavbarResponsive.current.checked = false)} isActive={() => { return position === "beneficios"}} to="/#beneficios" className="opccion-menu"  scroll={el => scrollWithOffset(el)}>Beneficios</NavHashLink>

                                <NavHashLink smooth activeClassName="opccion-menu-active" onClick={() => (refNavbarResponsive.current.checked = false)} isActive={() => { return position === "plantillas"}} to="/#plantillas" className="opccion-menu"  scroll={el => scrollWithOffset(el)}>Plantillas</NavHashLink>

                                <NavHashLink smooth activeClassName="opccion-menu-active" onClick={() => (refNavbarResponsive.current.checked = false)} isActive={() => { return position === "tutienda"}} to="/#tutienda" className="opccion-menu"  scroll={el => scrollWithOffset(el)}>Tu tienda</NavHashLink>

                                <NavHashLink smooth activeClassName="opccion-menu-active" onClick={() => (refNavbarResponsive.current.checked = false)} isActive={() => { return position === "comofunciona"}} to="/#comofunciona" className="opccion-menu"  scroll={el => scrollWithOffset(el)}>Cómo funciona</NavHashLink>

                                <NavHashLink smooth activeClassName="opccion-menu-active" onClick={() => (refNavbarResponsive.current.checked = false)} isActive={() => { return position === "planes"}} to="/#planes" className="opccion-menu" scroll={el => scrollWithOffset(el)}>Planes</NavHashLink>

                                <NavHashLink smooth activeClassName="opccion-menu-active" onClick={() => (refNavbarResponsive.current.checked = false)} isActive={() => { return position === "contacto"}} to="/#contacto" className="opccion-menu"  scroll={el => scrollWithOffset(el)}>Contacto</NavHashLink>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SideNav></SideNav>
    </div>
)}
export default NavBar;