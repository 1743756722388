import React, {useState} from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import OnSubmitValidationError from "../../hooks/formikOnSubmitValidationError";
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import AuthApi from "../../api/authApi";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AuthOrRegister = () => {
    
    const classes = useStyles();
    let history = useHistory();
    let { id } = useParams();
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const authService = AuthApi(window.runConfig.apiUrl);

    const handleForgotPassword = (values) => {

        setOpen(true);
        
        authService.forgotPassword(values).then(
            result => {
                localStorage.setItem('tempAuthDataForgot', JSON.stringify({...values, ...result}));
                history.push(`/subscription/${id}/confirmForgotPassword`);
            },
            error => {
                setOpen(false);
                setErrorMessage(error.error);
                setOpenAlert(true);
            }
        );
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlert(false);
    };

    const handleSubmitError = ({errors}) => {
        setErrorMessage("Hay algunos errores en el formulario");
        setOpenAlert(true);
    }

    const inicialValue = {
        'username': ''
    }

    const ValidationSchema = Yup.object(
    {
        username: Yup.string()
            .required('El nombre de usuario/email es requerido')
            .test('checkDuplUsername', 'El nombre de usuario o email no existe', function (value) {
                return new Promise((resolve, reject) => {
                    authService.existEmailOrUser(value)
                        .then((result) => resolve(result))
                })
            }),
    });


    return (
        <div className="col-md-12 text-center padding-top-18">
            <div className="col-md-12 col-sm-12  margin-bottom-3">
                <span className="color-blue titulo1">Recuperar </span>
                <span className="color-green titulo1">contraseña:</span>
            </div>
            <div className="col-md-12 col-sm-12  margin-bottom-2">
                <span className="texto-general margin-bottom-3">Ingresa tu usuario o correo electrónico</span>
            </div>
            <div className="col-md-12 ajuste-input margin-bottom-3">
                <Formik initialValues= {inicialValue} validationSchema={ValidationSchema} onSubmit={handleForgotPassword}>   
                    {({ values,  errors, touched, handleSubmit, formik }) => 
                    ( 
                        <Form onSubmit={handleSubmit}>

                            <OnSubmitValidationError callback={handleSubmitError} />
                            <Field name="username" className={errors.username && touched.username ? "border-red": "border-blue"} type="text" />
                            <ErrorMessage className="texto-error" component="span" name="username"/>

                            <div className="row justify-content-center margin-top-2">
                                <a href="# " onClick={handleSubmit} className="btn btn-azul"> Continuar </a>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
};

export default AuthOrRegister;