import React, {useState, useRef, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import "./home.css";
import MainCarousel from '../../components/CarouselMain/mainCarousel';
import MainCarousel2 from '../../components/CarouselInfo/mainCarousel2';
import Calculadora from '../../components/CaculadoraComision/CalculadoraComision';
import CalculadoraVisanet from '../../components/CaculadoraComision/CalculadoraComisionVisanet';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ReCAPTCHA from "react-google-recaptcha";

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import OnSubmitValidationError from "../../hooks/formikOnSubmitValidationError";

import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import {useScrollPosition} from "../../hooks/useScroll";
import useGlobalState from "../../hooks/useGlobalState";

import subscriptionApi from '../../api/subscriptionService';

const useStyles = makeStyles(theme => ({
	backdrop: {
	  zIndex: theme.zIndex.drawer + 1,
	  color: '#fff',
	},
}));

const Alert = (props) => {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const subscriptionService = subscriptionApi(window.runConfig.apiUrl);

const Home = () => {

	let history = useHistory();
	const classes = useStyles();
	const [openDialog, setOpenDialog] = React.useState(false);
	const [selectedSubscription, setSelectedSubscription] = React.useState(3);
	const [selectedRegimen, setRegimen] = useState('pagosTrimestrales');
	const [open, setOpen] = useState(false);
	const [openAlert, setOpenAlert] = useState(false);
	const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
	const [premiumActive, setPremiumStatus] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [domain, setDomain] = useState("");
	const recaptchaRef = React.createRef();
	const [searchFocus, setFocus] = useState(false);
	const formref = useRef(null); 

	const home = useRef(null);
	const plantillas = useRef(null);
	const beneficios = useRef(null);
	const tutienda = useRef(null);
	const contacto = useRef(null);
	const comofunciona = useRef(null);
	const planes = useRef(null);

	const globalState = useGlobalState();

	const handleBlur = () => {
		setFocus(false);
	}
	
	const handleFocus = () => {
		setFocus(true);
	}
	
	const keyPressed = (event) => {
		if (event.key === "Enter" && searchFocus == true) {
			if (formref.current) {
				handleDomainFind()
			}
		}
	}
	

	useScrollPosition(({ prevPos, currPos }) => {

		let position = "";

		if(home !== null){
			const homeOffset = (home.current.offsetTop - 210);
			if ((currPos.y * -1) > homeOffset){
				position = "home";
			}
		}
	
		if(beneficios !== null){
			const beneficiosOffset = (beneficios.current.offsetTop - 210);
			if ((currPos.y * -1) > beneficiosOffset){
				position = "beneficios";
			}
		}

		if(plantillas !== null){
			const plantillasOffset = (plantillas.current.offsetTop - 210);
			if ((currPos.y * -1) > plantillasOffset){
				position = "plantillas";
			}
		}
		
		if(tutienda !== null){
			const tutiendaOffset = (tutienda.current.offsetTop - 210);
			if ((currPos.y * -1) > tutiendaOffset){
				position = "tutienda";
			}
		}

		if(comofunciona !== null){
			const comofuncionaOffset = (comofunciona.current.offsetTop - 210);
			if ((currPos.y * -1) > comofuncionaOffset){
				position = "comofunciona";
			}
		}

		if(planes !== null){
			const planesOffset = (planes.current.offsetTop - 210);
			if ((currPos.y * -1) > planesOffset){
				position = "planes";
			}
		}
	
		if(contacto !== null){
			const contactoOffset = (contacto.current.offsetTop - 210);
			if ((currPos.y * -1) > contactoOffset){
				position = "contacto";
			}
		}

		globalState.setScroll(position);
	});

	const formValues = {
        'name': '',
        'email': '',
        'phone': '',
        'question': ''
    }

    const formValidation = Yup.object(
        {
            name: Yup.string()
                .required('Campo requerido'),
            email: Yup.string()
				.email('Debe ser un email válido')
				.required('Campo requerido'),
			phone: Yup.string()
				.required('Campo requerido'),
			question: Yup.string()
				.required('Campo requerido'),
        });
	
	const handleOptionChange = (e) => {
		setRegimen(e.target.value);
	}

	const handleOpenDialog = (id) => {
		setSelectedSubscription(id);
		localStorage.setItem('regimenFiscal', selectedRegimen)
        history.push(`/subscription/${selectedSubscription}/step1`);
	}
	
    const handleDialogOk = (e) => {
		e.preventDefault();
		history.push(`/subscription/${selectedSubscription}/step1`);
		localStorage.setItem('regimenFiscal', selectedRegimen);
        setOpenDialog(false);
    };

    const handleDialogCancel = (e) => {
        e.preventDefault();
        setOpenDialog(false);
	};

	const gotoDemo = (e) => {
		e.preventDefault();
		history.push(`/demo`);
	}

	const gotoHasPreviusDomain = (e) => {
		e.preventDefault();
		history.push(`/selectOwnDomain`);
	}

	const handleDomainFind = (e) => {
		if(domain.includes(".")){
			localStorage.setItem("domain", domain);
			history.push(`/domainFinder`);
		}else{
			localStorage.setItem("domain", `${domain}.com`);
			history.push(`/domainFinder`);
		}
	}

	const handleDomainSelect = (e) => {
		localStorage.setItem("domain", `tutienda.com`);
		history.push(`/domainFinder`);
	}

	const handleSendQuestion = (values, {resetForm}) => {
		
		const recaptchaValue = recaptchaRef.current.getValue();

		/* if(recaptchaValue === ""){
			setErrorMessage("Debe superar la prueba de identidad");
			setOpenAlert(true);
			return;
		} */

		setOpen(true);

		subscriptionService.sendQuestion(values).then(
			(result) => {
				setOpen(false);
				setOpenAlertSuccess(true);
				resetForm();
            },

            (error) => {
                setOpen(false);
                setErrorMessage(error.error);
                setOpenAlert(true);
            }
		);
	}

	const handleSubmitError = ({errors}) => {
		setErrorMessage("Hay algunos errores en el formulario de contacto");
		setOpenAlert(true);
	}
	
	const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlert(false);
	};

	const handleCloseSuccess = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlertSuccess(false);
	};

	return (
		<div>
    		<div className="contenedor-banner" id="home" ref={home}>
				{premiumActive? (
					<div className="contener-absoluto">
						<div className="container container-especial padding-0">
							<div className="col-md-7 col-sm-12">
								<div className="contenedor-premium margin-bottom-1">
									<p className="texto-banner color-white text-center">Adquiere tu cuenta gratuita</p>
									<div className="contenedor-btn-dominio margin-left-1">
										<div onClick={gotoDemo} className="btn btn-dominio"> Clic aquí</div>
									</div>
								</div>
								<div className="borde-general-banner">
									<p className="titulo-banner color-white text-center margin-bottom-1">¡Adquiere tu tienda hoy!</p>
									<p className="texto-banner color-white">Busca un dominio</p>
									<div>
										<div className="search-container">
											<input ref={formref} key="keyunica" className="texto-general" placeholder="Ejemplo: tutienda.com" value={domain} onChange={e => setDomain(e.target.value)} onFocus={handleFocus} onBlur={handleBlur} onKeyPress={keyPressed}></input>
											<a className="btn-search" onClick={handleDomainFind}><i className="fa fa-search" aria-hidden="true"></i></a>
										</div>
									</div>
									<div onClick={gotoHasPreviusDomain} className="texto-general color-white cursor-pointer"> Si ya tienes dominio haz clic aquí </div>
									<p className="texto-banner color-white margin-top-3">Tu tienda Chappsy 100% lista para iniciar a vender</p>
								</div>	 
							</div>
						</div>
					</div>
				): (
					<div className="contener-absoluto">
						<div className="container container-especial padding-0">
							<div className="col-md-7 col-sm-12">
								<div className="contenedor-premium margin-bottom-1">
									<p className="texto-banner color-white text-center">Adquiere tu cuenta premium</p>
									<div className="btn-premium" onClick={()=>{setPremiumStatus(true)}}>
										<img className="img-premium" src={process.env.PUBLIC_URL + '/images/premium.png'} alt="premium" />
										<img className="img-premiumqueen" src={process.env.PUBLIC_URL + '/images/premiumqueen.png'} alt="premium" />
										<span className="span-clic-premium">Clic aquí</span>
									</div>
								</div>
								<div className="borde-general-banner">
									<p className="titulo-banner color-white">¡Activa tu tienda</p>
									<p className="titulo-banner color-white">Gratis hoy!</p>
									<div className="contenedor-btn-dominio margin-bottom-3 margin-top-3">
										<div onClick={gotoDemo} className="btn btn-dominio"> Clic aquí</div>
									</div>
									<p className="texto-banner color-white">Tu tienda Chappsy 100% lista para iniciar a vender</p>
								</div>	 
							</div>
						</div>
					</div>
				)}
				<img className="img-banner margin-bottom-3 ocultar-banner" src={process.env.PUBLIC_URL + '/images/bannerfondo.png'} alt="banner" />
				<img className="img-banner-responsive margin-bottom-3 mostrar-banner" src={process.env.PUBLIC_URL + '/images/bannerfondoresponsive.png'} alt="banner" />
			</div>
			<div className="row margin-bottom">
				<div className="col-md-8 col-sm-12">
					<img className="img-general" src={process.env.PUBLIC_URL + '/images/img1.png'} alt="chappsy" />
				</div>
				<div className="col-md-4 col-sm-12 columna-flex responsive-top-3">
					<span className="color-blue titulo1">¿Qué es </span>
					<span className="color-green titulo1">Chappsy?</span>
					<p className="texto-general text-justify">Es la primera red de tiendas en línea de Guatemala, que te ofrece la posibilidad de tener tu propia tienda totalmente personalizable con tu marca.</p>
					<p className="texto-general text-justify">Con Chappsy puedes vender cualquier tipo de producto desde una plataforma fácil de usar, con un procesador de pago integrado y certificado por:</p>
					<span> 
						<img className="logo-visanet" src={process.env.PUBLIC_URL + '/images/logovisanet.png'} alt="logovisanet" />
						<img className="logo-visanet margin-left-2" src={process.env.PUBLIC_URL + '/images/pci.png'} alt="pci" />
					</span>
				</div>
			</div>

			<div className="row text-center margin-bottom" id="beneficios" ref={beneficios}>
				<div className="col-md-12 col-sm-12 margin-bottom margin-bottom-responsive">
					<span className="color-blue titulo1">Beneficios al suscribirte a </span>
					<span className="color-green titulo1">Chappsy</span>
				</div>
				<div className="container">
					<MainCarousel2></MainCarousel2>
				</div>
			</div>

			<div className="row margin-bottom align-items-center justify-content-center" id="plantillas" ref={plantillas}>
				<div className="col-md-12 col-sm-12 margin-bottom-3 margin-bottom-responsive text-center">
					<span className="color-blue titulo1">Plantillas </span>
					<span className="color-green titulo1">personalizables</span>
				</div>
				<div className="col-md-4 col-sm-12 demo-inner" id="classic">
					<div className="layout-image">
						<a target="_blank" href="https://classic.chappsy.com/" className="preview-btn">Ver demo</a>
						<a className="template-scroll" href="https://classic.chappsy.com/" target="_blank"></a>
					</div>
					<p className="titulo2 color-blue text-center height-parrafo">Plantilla Classic</p>
				</div>
				<div className="col-md-4 col-sm-12 demo-inner" id="natural">
					<div className="layout-image">
						<a className="preview-btn" target="_blank" href="https://naturalfood.chappsy.com/">Ver demo</a>
						<a className="template-scroll" href="https://naturalfood.chappsy.com/" target="_blank"></a>
					</div>
					<p className="titulo2 color-blue text-center height-parrafo">Plantilla Natural Food</p>
				</div>
				<div className="col-md-4 col-sm-12 demo-inner" id="electronic">
					<div className="layout-image">
						<a className="preview-btn" target="_blank" href="https://electronicbus.chappsy.com/">Ver demo</a>
						<a className="template-scroll" href="https://electronicbus.chappsy.com/" target="_blank"></a>
					</div>
					<p className="titulo2 color-blue text-center height-parrafo">Plantilla Electronic Bus</p>
				</div>
			</div>

			<div className="row text-center margin-bottom" id="tutienda" ref={tutienda}>
				<div className="col-md-12 col-sm-12 margin-bottom-3">
					<span className="color-blue titulo1">Administra tu tienda en </span>
					<span className="color-green titulo1">CES</span>	
				</div>
				<div className="col-md-12">
					<MainCarousel></MainCarousel>
				</div>
			</div>

			<div className="row text-center margin-bottom" id="comofunciona" ref={comofunciona}>
				<div className="col-md-12 col-sm-12 margin-bottom-3">
					<span className="color-blue titulo1">¿Cómo funciona </span>
					<span className="color-green titulo1">Chappsy?</span>
				</div>
				<div className="row-wrap margin-bottom-3">
					<div className="col-md-6 col-sm-12">
						<div className="row">
							<div className="col-md-3 col-sm-12">
								<img className="img-comofunciona" src={process.env.PUBLIC_URL + '/images/icono1.png'} alt="suscripcion" />
							</div>
							<div className="col-md-9 col-sm-12 text-left height-profit">
								<span className="color-1 number-size">#1</span>
								<p className="titulo2 color-blue">Activa tu tienda gratis o premium</p>
								<p className="texto-general text-justify">Regístrate en Chappsy y activa tu tienda.</p>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-12">
						<div className="row">
							<div className="col-md-3 col-sm-12">
								<img className="img-comofunciona" src={process.env.PUBLIC_URL + '/images/icono6.png'} alt="procesador" />
							</div>
							<div className="col-md-9 col-sm-12 text-left height-profit">
								<span className="color-2 number-size">#2</span>
								<p className="titulo2 color-blue">Adquiere tu propio procesador</p>
								<p className="texto-general text-justify">Un representante de Chappsy te ayudará a gestionar tu propio procesador Visanet, con el cual podrás recibir tus pagos directamente en tu cuenta bancaria.</p>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-12">
						<div className="row">
							<div className="col-md-3 col-sm-12">
								<img className="img-comofunciona" src={process.env.PUBLIC_URL + '/images/icono7.png'} alt="adquiere" />
							</div>
							<div className="col-md-9 col-sm-12 text-left height-profit">
								<span className="color-3 number-size">#3</span>
								<p className="titulo2 color-blue">Adquiere tu C-Card</p>
								<p className="texto-general text-justify">Adquiere tu tarjeta prepago con la cual podrás pagar los servicios de la plataforma.</p>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-12">
						<div className="row">
							<div className="col-md-3 col-sm-12">
								<img className="img-comofunciona" src={process.env.PUBLIC_URL + '/images/icono2.png'} alt="productos" />
							</div>
							<div className="col-md-9 col-sm-12 text-left height-profit">
								<span className="color-4 number-size">#4</span>
								<p className="titulo2 color-blue">Sube tus productos</p>
								<p className="texto-general text-justify">Carga el listado y fotografías de tus productos para publicarlos en tu tienda, la cual podrás comenzar a promocionar por medio de tus redes sociales.</p>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-12">
						<div className="row">
							<div className="col-md-3 col-sm-12">
								<img className="img-comofunciona" src={process.env.PUBLIC_URL + '/images/icono3.png'} alt="recibe" />
							</div>
							<div className="col-md-9 col-sm-12 text-left height-profit">
								<span className="color-5 number-size">#5</span>
								<p className="titulo2 color-blue">Recibe tus pedidos</p>
								<p className="texto-general text-justify">Obtén un acceso a un administrador donde podrás visualizar el listado de compras realizadas en tu tienda con todos los datos de entrega de cada pedido. Además, recibirás notificaciones por medio de correo electrónico.</p>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-12">
						<div className="row">
							<div className="col-md-3 col-sm-12">
								<img className="img-comofunciona" src={process.env.PUBLIC_URL + '/images/icono4.png'} alt="entrega" />
							</div>
							<div className="col-md-9 col-sm-12 text-left height-profit">
								<span className="color-6 number-size">#6</span>
								<p className="titulo2 color-blue">Entrega tus pedidos</p>
								<p className="texto-general text-justify">Coordina las entregas con tus mensajeros o tu empresa de paquetería de confianza.</p>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12 col-sm-12">
					<img className="img-calculadora" src={process.env.PUBLIC_URL + '/images/calculadora.png'} alt="calculadora" />
				</div>
				<div className="col-md-12">
					<CalculadoraVisanet/>
				</div>
				<div className="col-md-12 contenedor-btn-dow">
					<p className="texto-general margin-0">Si aún no tienes tu propio procesador de Visanet, nosotros te ayudamos a gestionar tu contrato sin costo.</p>
					<p className="texto-general margin-0">Revisa los requisitos en el botón de descarga.</p>
					<a className="row-flex btn-dow btn margin-top-2" download target="_blank" href="https://chappsybucked.s3.amazonaws.com/chappsy/requisitos_solicitud_procesador_visanet.pdf">
						<img className="img-dow" src={process.env.PUBLIC_URL + '/images/download.svg'} alt="descarga" />
						Descargar requisitos
					</a>
				</div>	
			</div>

			<div className="row margin-bottom" id="planes" ref={planes}>
				<div className="col-md-12 text-center margin-bottom-3">
					<span className="color-blue titulo1">Planes de </span><span className="color-green titulo1">activación</span>
				</div>
				<div className="col-md-12">
					<table className="table-planes">
						<thead>
							<tr>
								<th className="width-table-planes-1">Características</th>
								<th className="width-table2">
									<p className="margin-0">Cuenta</p>
									<p className="margin-0">Gratuita</p>
								</th>
								<th className="width-table3">
									<p className="margin-0">Cuenta</p>
									<p className="margin-0">Premium</p>
									<p className="margin-0"><span>Q180 al mes</span></p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Subdominio de Chappsy ejemplo: https://mitiendagt.chappsy.com</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td></td>
							</tr>
							<tr>
								<td>Dominio propio (Q150.00 anuales)</td>
								<td></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
							<tr>
								<td>Soporte técnico</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
							<tr>
								<td>Certificado de seguridad SSL</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
							<tr>
								<td>Carrito de compra</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
							<tr>
								<td>Selección de múltiples plantillas</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
							<tr>
								<td>Personalización plantilla, colores, logotipo, fotos de carrusel, secciones, etc.</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
							<tr>
								<td>Configuración de enlaces de redes sociales</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
							<tr>
								<td>Buscador de productos</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
							<tr>
								<td>Registro de clientes</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
							<tr>
								<td>Métodos de pago efectivo, tarjeta de crédito o débito Visa y Mastercard y Paypal</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
							<tr>
								<td>Sistema de inventario de productos, carga ilimitada de productos</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
							<tr>
								<td>Más de 50 categorías de productos disponibles</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
							<tr>
								<td>Informes de ventas, productos más vendidos y detalle de productos</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
							<tr>
								<td>Administrador de pedidos</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
							<tr>
								<td>Certificado SSL y subdominio de Chappsy</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
							<tr>
								<td>Sistema de notificaciones por cada compra</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
							<tr>
								<td>Configuración de SEO, Keywords, Meta Tittle, Meta Description, scripts Analytics de Google y Facebook</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
							<tr>
								<td>Sistema de publicaciones directas de productos a Facebook</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
							<tr>
								<td>Dashboard de tráfico</td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
								<td className="text-center"><i className="fa fa-check icono-check-planes" aria-hidden="true"></i></td>
							</tr>
						</tbody>
					</table>
				</div>		
				<div className="col-md-12 cont-btn-planes">
					<a className="btn btn-planes" onClick={gotoDemo} >Adquirir</a>
					<a className="btn btn-planes margin-left-1" onClick={handleDomainSelect}>Adquirir</a>
				</div>
			</div>

			<div className="row text-center margin-bottom" id="contacto" ref={contacto}>
				<div className="col-md-12 col-sm-12">
					<span className="color-blue titulo1">¿Necesitas </span>
					<span className="color-green titulo1">ayuda?</span>
					<p className="texto-general">Envíanos tus datos para que un asesor se comunique contigo.</p> 
				</div>
				<div className="col-md-12 margin-bottom-3">
					<img className="img-asesor" src={process.env.PUBLIC_URL + '/images/asesor.png'} alt="asesor" />
				</div>
				<div className="col-md-12">
					<Formik initialValues= {formValues} validationSchema={formValidation} onSubmit={handleSendQuestion}>
					{({ values,  errors, touched, handleSubmit, formik }) => 
					( 
						<Form onSubmit={handleSubmit}>

							<OnSubmitValidationError callback={handleSubmitError} />

							<div className="columna-flex align-items-center">
								<div className="col-md-5 col-sm-12">
									<span className="texto-contactanos">Nombre *</span>
									<Field name="name" className={errors.name && touched.name ? "border-red": "border-blue"} type="text" />
									<ErrorMessage component="span" className="texto-error" name="name"/>
								</div>
								<div className="col-md-5 col-sm-12">
									<span className="texto-contactanos">Correo electrónico *</span>
									<Field name="email" className={errors.email && touched.email ? "border-red": "border-blue"} type="text" />
									<ErrorMessage component="span" className="texto-error" name="email"/>
								</div>
								<div className="col-md-5 col-sm-12">
									<span className="texto-contactanos">Número de teléfono *</span>
									<Field name="phone" className={errors.phone && touched.phone ? "border-red": "border-blue"} type="text" />
									<ErrorMessage component="span" className="texto-error" name="phone"/>
								</div>
								<div className="col-md-5 col-sm-12">
									<span className="texto-contactanos">Consulta *</span>
									<Field name="question" className={errors.question && touched.question ? "border-red": "border-blue"}  component="textarea" rows="5" type="text" />
									<ErrorMessage component="span" className="texto-error" name="question"/>
								</div>
								<div className="col-md-5 col-sm-12">
									<div className="float-right">
										<ReCAPTCHA
											ref={recaptchaRef}
											sitekey="6LcN1-0UAAAAAGoWXKVIOi_1lQVuhw-TxXsB61BR"
										/>
									</div>
								</div>
								<div className="col-md-5 col-sm-12 text-right margin-top-1">
									<a className="btn btn-principal" onClick={handleSubmit} >Enviar</a>
								</div>
							</div>
						</Form>
					)}
					</Formik>
				</div>
			</div>

			<Dialog
				open={openDialog}
				onClose={handleDialogCancel}
				scroll="paper"
				aria-labelledby="dialog-title"
				aria-describedby="dialog-description"
				>
				<DialogTitle id="dialog-title">
					<div className="col-md-12 col-sm-12">
						<span className="color-blue titulo2">Pago de </span>
						<span className="color-green titulo2">suscripción</span>
						<p className="texto-general text-justify texto-regimen">Para poder adquirir tu tienda en línea es necesario que tú o tu empresa este registrado en la SAT (12% de IVA) con algunos de los siguientes regímenes, selecciona el tuyo:</p> 
					</div>
				</DialogTitle>
				<DialogContent>
					<div className="col-md-12 col-sm-12">
						<div className="form-check">
							<label className="container-check">
								<span className="texto-general texto-regimen regimen-1">1.-Sujeto a retención definitiva</span>
								<input
								type="radio"
								name="react-tips"
								value="pagosTrimestrales"
								checked={selectedRegimen === 'pagosTrimestrales'}
								onChange={handleOptionChange}
								className="form-check-input regimen-input"
								/>
								<span className="checkmark-check"></span>
							</label>
						</div>
						<div className="form-check">
							<label className="container-check">
								<span className="texto-general texto-regimen regimen-2">2.-Sujeto a pagos trimestrales. </span>
								<input
								type="radio"
								name="react-tips"
								value="retencionDefinitiva"
								checked={selectedRegimen === 'retencionDefinitiva'}
								onChange={handleOptionChange}
								className="form-check-input regimen-input"
								/>
								<span className="checkmark-check"></span>
							</label>
							
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<a href="#" onClick={handleDialogOk} className="btn btn-principal">
						Continuar
					</a>
				</DialogActions>
			</Dialog>

			<Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>

			<Snackbar open={openAlertSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
                <Alert onClose={handleCloseSuccess} severity="success">
                    Mensaje enviado con éxito
                </Alert>
            </Snackbar>

            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>                    
		</div>
	)
};

export default Home;