import React from "react";
import StringMask from "string-mask";
import { Field } from "formik";

const DELIMITER = "-";
const MASK = "0000-0000";

function removeTrailingCharIfFound(str, char) {
  return str
    .split(char)
    .filter(segment => segment !== "")
    .join(char);
}

function formatValue(str) {
  const unmaskedValue = str.split(DELIMITER).join("");
  const formatted = StringMask.process(unmaskedValue, MASK);
  return removeTrailingCharIfFound(formatted.result, DELIMITER);
}

export default function PhoneField(props) {
   
    return (
    <Field name={props.name}>
      {fieldProps => {
        return (
        <input
          className={props.className}
          {...fieldProps.field}
          onChange={event => {
            fieldProps.field.onChange(event.target.name)(
              formatValue(event.target.value)
            );
          }}
        />
      )}}
    </Field>
  );
}