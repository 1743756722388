import React, { useState } from "react";
import Cleave from 'cleave.js/react';
import "./calculadora.css";


const PrecioEnTienda = (props) => {
	let extra = 0;
    let precio = extra + props.precioNeto;
    precio = parseFloat(precio).toLocaleString('en-US', {minimumFractionDigits: 2});
    return (
        <p className="texto-general columna-flex align-items-center">
            <span className="height-title-calculadora">Precio publicado en tu tienda: </span>
            <span className="simulacion-input">
                <span className="q">Q.</span>
                {precio}
            </span>
        </p>
    )
}

const MontoARecibir = (props) => {
	let extra = props.precioNeto * 0.05;
    let precio = extra;
    precio = parseFloat(precio).toLocaleString('en-US', {minimumFractionDigits: 2});
    return (
        <p className="texto-general columna-flex align-items-center">
            <span className="height-title-calculadora">Pago por servicio de Chappsy 5% por transacción: </span>
            <span className="simulacion-input">
                <span className="q">Q.</span>
                {precio}
            </span>
        </p>
    )
}

const CalculadoraComisionVisanet = () => {

    const [precioNeto, setPrecioNeto] = useState(100);
    
    const handleOnChange = (event) => {

        let price = parseFloat(event.target.value.replace(/,/g, ''));
        setPrecioNeto(price);
    }

	return (
		<div>
            <div className="col-md-12 col-sm-12 margin-bottom-3">
                <span className="color-blue titulo1">Calculadora de </span>
                <span className="color-green titulo1">precios</span>
            </div>
            <div className="row margin-bottom-3">
                <div className="col-md-4 col-sm-12 columna-flex align-items-center">
                    <p className="texto-general height-title-calculadora">Ingresa el precio del producto:</p>
                    <div className="left-inner-addon">
                        <span>Q.</span>
                        <Cleave 
                            name="precioNeto"
                            options={{
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand'
                            }}
                            value="100"
                            onChange={handleOnChange}
                        />
                    </div>
                </div>
                <div className="col-md-4 col-sm-12 columna-flex align-items-center">
    			   <MontoARecibir precioNeto={precioNeto}/>
                </div>
                <div className="col-md-4 col-sm-12 columna-flex align-items-center">
    			   <PrecioEnTienda precioNeto={precioNeto}/>
                </div>
            </div>
		</div>
	);
}

export default CalculadoraComisionVisanet;