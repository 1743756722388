/* global localStorage */
import React, {useState, useEffect, useRef} from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import OnSubmitValidationError from "../../hooks/formikOnSubmitValidationError";
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import SubsApi from "../../api/subscriptionService";
import { makeStyles } from '@material-ui/core/styles';
import "../demo/demo.css";

const useStyles = makeStyles(theme => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Demo = () => {

	const classes = useStyles();
    let history = useHistory();
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
	const subscriptionService = SubsApi(window.runConfig.apiUrl);


    useEffect(() => {
        window.scrollTo(0, 0);
	}, []);

	const handleRegister = ({store}) => {

        localStorage.setItem('domainSelect', store);
        localStorage.setItem('hasPreviusDomain', true);
        history.push(`/subscription/select`);
    }


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlert(false);
    };

    const handleSubmitError = ({errors}) => {
        setErrorMessage("Hay algunos errores en el formulario");
        setOpenAlert(true);
    }

	const inicialValueRegister = {
		'store': '',
    }

    const registerValidationSchema = Yup.object(
        {
			store: Yup.string()
                .required('Campo requerido')
                .test('notDots', 'Caracteres especiales no permitidos', function (value) {
                    return !(/[!$%^&*()_+|~=`{}[:;<>?,@#\]]/g.test(value));
                })
                .test('checkDuplDomain', 'Subdominio en uso', function (value) {
                    return new Promise((resolve, reject) => {
                        subscriptionService.isValidSubdomain(value)
                            .then((result) => {
                                return resolve(!result)
                            })
                    })
                })
				.min(4, "Mínimo 8 caracteres"),
		}
	);

    return (
        <div className="row margin-bottom padding-top-18 padding-formulario" >
            <div className="col-md-12 col-sm-12 margin-bottom-3 text-center">
				<span className="color-blue titulo1">Activa tu </span>
				<span className="color-green titulo1">tienda</span>	
			</div>

			<Formik initialValues= {inicialValueRegister} validationSchema={registerValidationSchema} onSubmit={handleRegister}>
                {({ values,  errors, touched, handleSubmit, formik }) => 
                ( 
                <Form onSubmit={handleSubmit} className="col-md-12">

                    <OnSubmitValidationError callback={handleSubmitError} />

					<div className="row margin-bottom-1">
                        <div className="col-md-12 text-center">
                            <p className="texto-general"><span className="titulo2">Escribe el nombre de tu tienda</span></p> 
                        </div>
                        <div className="col-md-12 dominio-search activar-dominio">
                            <Field name="store" placeholder="Ejemplo: tutienda" className={errors.store && touched.store ? "border-red texto-general": "border-blue texto-general"} type="text"/>
                            <img className="img-lupa" src={process.env.PUBLIC_URL + '/images/lupa.png'} alt="chappsy" />
                        </div>

						<div className="contenedor-error-message">
				            <ErrorMessage component="span" className="texto-error" name="store"/>
                        </div>
					</div>

                    <div className="centrar-btn margin-top-2">
                        <a className="btn btn-principal" onClick={handleSubmit} >Siguiente</a>
                    </div>
                </Form>
                )}
            </Formik>
			<Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="error">
					{errorMessage}
				</Alert>
			</Snackbar>
			<Backdrop className={classes.backdrop} open={open}>
				<CircularProgress color="inherit" />
			</Backdrop>
        </div>
    );
}

export default Demo;