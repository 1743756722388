import React from "react";
import InfoPage1 from "./CarouselContent/InfoPage3"
import InfoPage2 from "./CarouselContent/InfoPage2"
import InfoPage3 from "./CarouselContent/InfoPage1"
import InfoPage4 from "./CarouselContent/InfoPage4"
import InfoPage5 from "./CarouselContent/InfoPage5"
import InfoPage6 from "./CarouselContent/InfoPage6"

const CarouselContent = (props) => {
    switch(props.currentSlide) {
        case 0:
            return <InfoPage1/>;
        case 1:
            return <InfoPage2/>;
        case 2:
            return <InfoPage3/>;
        case 3:
            return <InfoPage4/>;
        case 4:
            return <InfoPage5/>;
        case 5:
            return <InfoPage6/>;
        default:
          return <div>No hay pagina correspondiente a este slide</div>;
      }
}

export default CarouselContent;