import React, {useEffect, useRef, useState} from "react";
import { useHistory, useParams } from "react-router-dom";

import "./domain.css";
import subscriptionService from "../../api/subscriptionService";

import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useGlobalState } from "../../hooks/useGlobalState";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Domain = () => {

    const classes = useStyles();
	let history = useHistory();
    let { id } = useParams();
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
	const subscriptionApi = subscriptionService(window.runConfig.apiUrl);

    useEffect(() => {
		window.scrollTo(0, 0);
		consultarDominio();
	}, []);

	const [disponibilidad, setDisponibilidad] = useState("UNAVAILABLE"); //AVAILABLE
	const [domain, setDomain] = useState(localStorage.getItem("domain") || "tutienda.com");
	const [fixedDomain, setFixedDomain] = useState("tutienda.com");
	const [suggestions, setSuggestions] = useState([]);
	const [searchFocus, setFocus] = useState(false);
	const formref = useRef(null); 

	const handleBlur = () => {
		setFocus(false);
	}
	
	const handleFocus = () => {
		setFocus(true);
	}
	
	const keyPressed = (event) => {
		if (event.key === "Enter" && searchFocus == true) {
			if (formref.current) {
				consultarDominio()
			}
		}
	}

	const gotoHasPreviusDomain = (e) => {
		e.preventDefault();
		history.push(`/selectOwnDomain`);
	}

	const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlert(false);
	};

	const nextPage = (domainSelect) => {
		
		localStorage.setItem('domainSelect', domainSelect);
		localStorage.setItem('hasPreviusDomain', false);
		
		if(id == "undefined"|| id == undefined ){
			history.push(`/subscription/select`);
		}

		if(id){
			history.push(`/subscription/${id}`);
		}
	}
	
	const DisponibleLabel = () => {
		return (
			<span>
				Disponible <i class="fa fa-check color-green" aria-hidden="true"></i> 
			</span>
		)
	}

	const NoDisponibleLabel = () => {
		return (
			<span>
				No Disponible <i class="fa fa-times color-red" aria-hidden="true"></i>
			</span>
		)
	}
	
	const consultarDominio = () => {

		setOpen(true);

		let domainToSearch = domain;

		if(domainToSearch.includes(".")){
			domainToSearch = domain;
		}else{
			domainToSearch = `${domain}.com`;
		}

		setFixedDomain(domainToSearch)

		let availabilityPromise = subscriptionApi.checkAvailability(domainToSearch).then(
			(result) => {
				setDisponibilidad(result);
            },

            (error) => {
                setErrorMessage(error.error);
                setOpenAlert(true);
            }
		);

		let suggestions = subscriptionApi.getSuggestion(domainToSearch).then(
			(result) => {
				setSuggestions(result);
            },

            (error) => {
                setErrorMessage(error.error);
                setOpenAlert(true);
            }
		)

		Promise.all([availabilityPromise, suggestions]).then((result) => {
			setOpen(false);
		});
	}

    return (
        <div className="row margin-bottom padding-top-18 padding-formulario">
			<div className="col-md-12 text-center margin-bottom-3">
				<span className="color-blue titulo1">Resultados de la </span>
				<span className="color-green titulo1">búsqueda</span>	
			</div>
			<div className="col-md-12 margin-bottom-3">
				<div>
					<div className="dominio-search">
						<input className="texto-general" ref={formref} name="" placeholder="tutienda" value={domain} onChange={e => setDomain(e.target.value)} onFocus={handleFocus} onBlur={handleBlur} onKeyPress={keyPressed} type="text" />
						<a className="btn-search" onClick={consultarDominio}><i class="fa fa-search" aria-hidden="true"></i></a>
					</div>
					<div onClick={gotoHasPreviusDomain} className="texto-general cursor-pointer"> Si ya tienes dominio haz clic aquí </div>
				</div>
			</div>
			<div className="col-md-12 margin-bottom-1">
				<p className="titulo2 color-blue">Disponibilidad de "{fixedDomain}"</p>
				<table className="disponibilidad">
					<thead>
						<tr>
							<th>Nombre del dominio</th>
							<th className="text-center-responsive">Precio 1 año</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="width-50">{fixedDomain}</td>
							<td>{disponibilidad === 'AVAILABLE'? 'Q150.00': '-'}</td>
							<td className="text-right">{disponibilidad === 'AVAILABLE'? <a href="#" onClick={() => {nextPage(fixedDomain)}} className="btn btn-azul btn-azul-responsive">Adquirir</a> : <NoDisponibleLabel></NoDisponibleLabel>}</td>
						</tr>
					</tbody>
				</table>
        	</div>
        	<div className="col-md-12">
				<p className="titulo2 color-blue">Sugerencias de dominios relacionados</p>
				<table className="sugerencias">
					<thead>
						<tr>
							<th>Nombre del dominio</th>
							<th className="text-center-responsive">Precio 1 año</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{suggestions.map((suggestion, index) => (
							<tr key={suggestion.DomainName}>
								<td className="width-50">{suggestion.DomainName}</td>
								<td>Q150.00</td>
								<td className="text-right"><a href="#" onClick={() => {nextPage(suggestion.DomainName)}} className="btn btn-azul btn-azul-responsive">Adquirir</a></td>
							</tr>
						))}
					</tbody>
				</table>
        	</div>
			<Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default Domain;